<template>
  <div class="container">
    <!-- header -->
    <div class="card-content">
      <router-link :to="{ name: 'claim' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >description</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <router-link :to="{ name: 'claim' }"
          ><i class="small material-icons" title="Go Back"
            >subdirectory_arrow_left</i
          ></router-link
        >
        <small> View Claims</small> 
      </h3>
    </div>

    <div class="row">
      <div class="col s12">
        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <!-- menu -->
          <div class="section">
            <div class="card">
              <span
                class="new badge left"
                data-badge-caption="Scheme"
                v-if="clusterScheme"
              >
                {{ clusterScheme.toUpperCase() }}
              </span>
              <div class="card-content">
                <span class="card-title black-text"
                  >{{ clusterProvider }}
                  <span class="chip"
                    ><b>{{ clusterPeriod }} Claims</b></span
                  >
                  <span class="chip green lighten-4" v-if="complete"
                    ><em><b>Vetted</b></em></span
                  >
                  <span class="chip yellow lighten-4" v-else
                    ><em><b>Unvetted</b></em></span
                  >
                  <span class="right">
                    <a
                      href="#"
                      class="secondary-content teal-text text-darken-1 dropdown-trigger"
                      data-target="dropmenu"
                      ><i class="small material-icons" title="review"
                        >more_vert</i
                      ></a
                    >

                    <!-- DropMenu -->
                    <ul id="dropmenu" class="dropdown-content">
                      <li @click.prevent="handleReview('display')">
                        <a href="#"
                          >View All Pages
                          <b
                            >({{
                              encounters.length ? encounters.length : 0
                            }})</b
                          ></a
                        >
                      </li>
                      <li class="divider" tabindex="-1"></li>
                      <li @click.prevent="handleReview('status')">
                        <a href="#">Show Status</a>
                      </li>
                      <span v-if="clusterStatus == 'p'">
                        <li class="divider" tabindex="-1"></li>
                        <li @click.prevent="handleReview('vet')">
                          <a href="#">Vet Claims</a>
                        </li>
                      </span>
                    </ul>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <!-- Vet claim -->
          <VetClaim
            :encounterId="encounterId"
            :cluster="encounters"
            :clusterStatus="clusterStatus"
            @vetted="handleVetted"
            v-if="currState == 'v'"
          />

          <!-- Display claims -->
          <template v-if="currState == 'd'">
            <div class="card">
              <div class="card yellow lighten-5">
                <div class="card-content white">
                  <table class="highlight responsive-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name<br /><small>(Enrollee ID)</small></th>
                        <th>Diagnosis</th>
                        <th>Sent Amount</th>
                        <th>Vetted Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="clickable"
                        :class="{
                          'grey lighten-5': encounter.status == 'p',
                        }"
                        v-for="(encounter, index) in encounters"
                        :key="encounter.id"
                        @click.prevent="handleStart(encounter)"
                      >
                        <template v-if="encounter.status == 'd'">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <s>{{ encounter.name }}</s
                            ><br />
                            <s>({{ encounter.enrollee_number }})</s>
                          </td>
                          <td>
                            <s>{{ encounter.diagnosis }}</s>
                          </td>
                          <td>
                            <s>
                              ₦{{
                                Number(encounter.sent)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </s>
                          </td>
                          <td>
                            <s>₦{{ totalApproved(encounter.claims) }}</s>
                          </td>
                          <td>
                            <i
                              class="material-icons grey-text pointable"
                              :title="getTitle(encounter)"
                              >check_circle
                            </i>
                          </td>
                        </template>

                        <template v-else>
                          <td>{{ index + 1 }}</td>
                          <td>
                            {{ encounter.name }}<br />
                            ({{ encounter.enrollee_number }})
                          </td>
                          <td>{{ encounter.diagnosis }}</td>
                          <td>₦{{ totalSent(encounter.claims) }}</td>
                          <td>₦{{ totalApproved(encounter.claims) }}</td>
                          <td>
                            <i
                              class="material-icons pointable"
                              :class="{
                                'yellow-text text-darken-2':
                                  encounter.status == 'p',
                                'teal-text text-lighten-3': !(
                                  encounter.status == 'p'
                                ),
                              }"
                              :title="getTitle(encounter)"
                              >{{
                                encounter.status == "p"
                                  ? "watch_later"
                                  : "check_circle"
                              }}
                            </i>
                          </td>
                        </template>
                      </tr>

                      <!-- Final total -->
                      <tr>
                        <td colspan="2"><br /></td>
                        <td>Total</td>
                        <td>
                          <b>₦{{ grossTotalSent(encounters) }}</b>
                        </td>
                        <td class="green-text text-darken-2">
                          <h5>₦{{ grossTotalApproved(encounters) }}</h5>
                        </td>
                        <td colspan="1"><br /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Submit -->
            <div class="card-content">
              <br />
              <button
                class="waves-effect waves-light btn-large black right"
                :class="{
                  disabled:
                    complete || clusterStatus == 'v' || clusterStatus == 'r',
                }"
                @click="handleReview('vet')"
              >
                <i class="medium material-icons right">chevron_right</i>Continue
                Vetting
              </button>
            </div>
          </template>

          <!-- Show status -->
          <template v-if="currState == 's'">
            <div class="card">
              <div class="card yellow lighten-5">
                <div class="card-content white">
                  <table class="responsive-table">
                    <thead>
                      <tr>
                        <th colspan="2"><br /></th>
                        <th>Sent Total</th>
                        <th>Approved Total</th>
                        <th>Status</th>
                        <th>TAT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="2">Payment</td>
                        <td>₦{{ grossTotalSent(encounters) }}</td>
                        <td
                          :class="{
                            'green-text text-darken-2':
                              clusterStatus == 'v' || clusterStatus == 'r',
                            'red-text': !(
                              clusterStatus == 'v' || clusterStatus == 'r'
                            ),
                          }"
                        >
                          <b>{{
                            clusterStatus == "v" || clusterStatus == "r"
                              ? `₦${grossTotalApproved(encounters)}`
                              : "Pending vetting"
                          }}</b>
                        </td>
                        <td>
                          <span
                            class="chip"
                            :class="{
                              'chip yellow lighten-4': !(
                                clusterPayment && clusterPayment.stage == 'c'
                              ),
                              'chip green lighten-4':
                                clusterPayment && clusterPayment.stage == 'c',
                            }"
                          >
                            {{
                              paymentStatus(
                                clusterStatus,
                                clusterPayment,
                                clusterRepayment
                              )
                            }}
                          </span>
                        </td>
                        <td>{{ clusterTAT }} days</td>
                        <td>
                          <span class="clickable edit-item">
                            <i class="material-icons grey-text">download</i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated, computed, watchEffect } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import {
  toMonthYear,
  getDifferenceInDays,
} from "@/composables/fxLibrary/coreFx.js";
import VetClaim from "@/components/claim/VetClaimComponent.vue";
import M from "materialize-css";

export default {
  name: "claimView",
  props: ["clusterId"],
  components: { LoadingDiv, VetClaim },
  setup(props) {
    const response = ref(null);
    const error = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const encounterId = ref(0);
    const clusterProvider = ref(null);
    const clusterPeriod = ref(null);
    const clusterScheme = ref(null);
    const clusterStatus = ref(null);
    const clusterPayment = ref(null);
    const clusterRepayment = ref(null);
    const clusterTAT = ref(null);
    const currState = ref("v"); // v=vet, d=display, s=status
    const complete = ref(false);

    // start
    onMounted(() => {
      // page name
      document.title = "View Claims | EasyHMO";
    });

    // get title
    const getTitle = (val) => {
      if (val.status == "p") {
        return "Un-Vetted Claim";
      } else if (val.status == "d") {
        return "Completed (Rejected)";
      } else {
        return "Completed";
      }
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      let total = 0;
      encounters.forEach((z) => {
        z.claims.forEach((x) => {
          if (x.sent_price) {
            total = Number(total) + Number(x.sent_price);
          }
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      let total = 0;
      encounters.forEach((z) => {
        z.claims.forEach((x) => {
          if (x.approved_price && x.status == "a") {
            // status has to be taken into acount for gross approved
            total = Number(total) + Number(x.approved_price);
          }
        });
      });

      total.toFixed(2);

      if (!db) {
        total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      // final
      return total;
    };

    // total calculations (sent)
    const totalSent = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        if (x.sent_price) {
          total = Number(total) + Number(x.sent_price);
        }
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // total calculations (approved)
    const totalApproved = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        if (x.approved_price) {
          total = Number(total) + Number(x.approved_price);
        }
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // payment status
    const paymentStatus = (status, payment, repayment) => {
      let val = "N/A";
      if (payment) {
        if (payment.stage == "p") {
          val = `(Batched #${payment.id})`;
        } else if (payment.stage == "q") {
          val = `(Queued #${payment.id})`;
        } else if (payment.stage == "c") {
          val = `(Paid #${payment.id})`;
        }
      } else {
        if (status == "v") {
          val = "Pending Batching";
        }
      }

      if (repayment) {
        if (repayment.stage == "p") {
          val = `(Batched #${repayment.id})`;
        } else if (repayment.stage == "q") {
          val = `(Queued #${repayment.id})`;
        } else if (repayment.stage == "c") {
          val = `(Paid #${repayment.id})`;
        }
      } else {
        if (status == "r") {
          val = "Pending Batching";
        }
      }

      return val;
    };

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // more
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // clusterPeriod getter
    onMounted(() => {
      if (props.clusterId) {
        (async () => {
          const { res } = await getItemsOfItems("cluster", props.clusterId);

          if (res.value) {
            // cluster period
            const { fx: monthYear } = toMonthYear(res.value.data.month_year);
            clusterPeriod.value = monthYear.value;
            clusterProvider.value = res.value.data.provider.name;
            clusterScheme.value = res.value.data.scheme.name;
            clusterStatus.value = res.value.data.status;
            clusterPayment.value = res.value.data.payment;
            clusterRepayment.value = res.value.data.repayment;

            // get TAT
            const { fx: inDays } = getDifferenceInDays(
              res.value.data.created_at,
              res.value.data.created_at
            );
            clusterTAT.value = inDays.value;

            // reset status
            if (clusterStatus.value == "v" || clusterStatus.value == "r") {
              currState.value = "s";
            }
          } else {
            // cluster Error
            error.value = "(Error) No Claims Encounter Found";
          }
        })();
      }
    });

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "encounter/cluster";
      // get items from function
      getItemsFromApi(currUrl.value, props.clusterId);
    });

    // switch page
    const handleStart = (encounter) => {
      // start spec laoding
      if (props.clusterId) {
        // re-assign
        encounterId.value = encounter.id;
        // switch to vetting
        currState.value = "v";
      }
    };

    // review
    const handleReview = (val) => {
      // switch to display
      if (val == "vet") {
        currState.value = "v";
      } else if (val == "display") {
        currState.value = "d";
      } else if (val == "status") {
        currState.value = "s";
      }
    };

    // vetted
    const handleVetted = async (val) => {
      // reload // val.status == 'success'
      if (val.status == "success" && clusterStatus.value == "p") {
        //after checking emits, checking cluster status
        // setup cluster
        let dbEntry = true;
        let payment_final = grossTotalApproved(val.value, dbEntry);
        let newVal = { payment_final: payment_final, status: "v" };

        // put update
        const { res, err } = await putItem("cluster", props.clusterId, newVal);

        if (res.value) {
          // update
          response.value = val.value;
          // update cluster
          clusterStatus.value = "v";
        } else {
          error.value = err.value;
        }
      }

      // switch to display
      currState.value = "d";
    };

    // monitor complete
    watchEffect(() => {
      if (response.value) {
        // start with
        complete.value = true;
        // update
        response.value.forEach((x) => {
          if (x.status == "p") {
            // set to false
            complete.value = false;
          }
        });
      }
    });

    // output results
    const encounters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      currState,
      handleStart,
      handleReview,
      handleVetted,
      grossTotalSent,
      grossTotalApproved,
      totalApproved,
      totalSent,
      paymentStatus,
      getTitle,
      isReady,
      isLoading,
      isSubmitting,
      error,
      encounterId,
      clusterProvider,
      clusterPeriod,
      clusterScheme,
      clusterStatus,
      clusterPayment,
      clusterRepayment,
      clusterTAT,
      complete,
      encounters,
    };
  },
};
</script>

<style scoped>
.custom-hr {
  width: 50%; /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc; /* Adjust the color and style as needed */
  margin: 20px 20px; /* Adjust margin as needed */
}

.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2; /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px; /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.pointable {
  cursor: default;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>