<template>
  <div class="section">
  <div class="card">
    <div class="card-content" v-if="enrollee">
      <!-- Main -->
      <div class="row">
        <div class="col s8">
          <p>{{ enrollee.client_name }}</p>
          <h5>
            {{ enrollee.name }}
            <sup>{{ showAgeSex(enrollee.dob, enrollee.sex) }}</sup>
          </h5>
          <p>({{ enrollee.enrollee_number }})</p>
          <span v-if="enrollee && 'encounter_date' in enrollee">
          <p class="teal-text">
            <i class="material-icons" title="Outpatient"
              >transfer_within_a_station</i
            >:
            <b>{{
              new Date(enrollee.encounter_date).toLocaleString(undefined, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}</b>
          </p>
        </span>
        <span v-else>
          <p>Encounter Date <em>not</em> set</p>
        </span>
        </div>

        <div class="col s4">
          <img
            src="@/assets/profile/40.jpg"
            width="96"
            class="circle responsive-img right"
          />
        </div>
      </div>
    </div>

    <!-- Enrollee Form -->
    <div
      class="card-panel"
      :class="{ 'red lighten-5': error }"
      v-if="!enrollee"
    >
      <div class="row">
        <div class="col s9">
          <div class="input-field">
            <input
              type="text"
              id="enrolleeId"
              v-model="enrolleeId"
              @focus="handleClearID('id')"
            />
            <label for="enrolleeId">Enrollee ID</label>

            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>
          </div>
        </div>
        <div class="col s3">
          <p>
            <button
              class="waves-effect waves-light btn-large right"
              :class="{
                disabled: !enrolleeId || isLoading,
              }"
              @click="handleSearch"
            >
              <i class="material-icons right" v-if="!isLoading">chevron_right</i
              >{{ isLoading ? "Searching..." : "Search" }}
            </button>
          </p>
        </div>
      </div>
    </div>

    <!-- Date Form -->
    <div
      class="card-panel"
      :class="{ 'red lighten-5': error }"
      v-if="enrollee && !('encounter_date' in enrollee)"
    >
      <div class="row">
        <div class="col s3">
          <div class="input-field">
            <input
              type="text"
              id="enounterDate"
              v-model="encounterDate"
              @focus="handleClearID('date')"
            />
            <label for="encounterDate">Day</label>
          </div>
        </div>
        <div class="col s3">
          <div class="input-field">
            <input
              type="text"
              id="month"
              :value="cluster.month.name"
              readonly
            />
            <label class="active" for="month">Month</label>
          </div>
        </div>
        <div class="col s3">
          <div class="input-field">
            <input type="text" id="year" :value="cluster.year.name" readonly />
            <label class="active" for="year">Year</label>
          </div>
        </div>
        <div class="col s3">
          <p>
            <button
              class="waves-effect waves-light btn-large right"
              :class="{
                disabled: !encounterDate || isLoading,
              }"
              @click="handleDate"
            >
              <i class="material-icons right">{{
                isLoading ? "hourglass_empty" : "chevron_right"
              }}</i
              >{{ isLoading ? "Going..." : "Next" }}
            </button>
          </p>
        </div>
      </div>
    </div>

    <template v-if="enrollee && 'encounter_date' in enrollee">
    <!-- Diagnosis -->
    <div class="row teal lighten-5" v-if="diagnosisList.length">
      <div class="col s12">
        <div class="card-content yellow lighten-1">
          <span class="black-text text-lighten-5">
            <p>
              Diagnosis: {{ diagnosisList.join(", ") }}
              <a href="#" class="secondary-content edit-black-item right"
                ><i
                  class="small material-icons"
                  :class="{
                    'black-text': !resetDiagnosis,
                    'blue-text': resetDiagnosis,
                  }"
                  title="edit diagnosis"
                  @click.prevent="handleReviewDiagnosis"
                  >edit</i
                ></a
              >
            </p>
          </span>
        </div>
      </div>
    </div>

    <!-- Add claim -->
    <AddManualClaim
      :type="type"
      :next="next"
      :editDiagnosis="resetDiagnosis"
      :claimItem="claim"
      :reload="reload"
      @update="handleUpdate"
      @clear="handleClear"
    />

    <!-- Claims -->
    <div class="card" v-if="claimsDisplayCard">
      <div class="card-content">
        <span class="card-title">Item(s)</span>

        <!-- List claims -->
        <ol id="item-list" class="collection">
          <li class="collection-item" v-for="claim in claims" :key="claim.id">
            <b>{{ claim.claim }}</b> x {{ claim.qty }} (<em>Sent Amount</em>: ₦{{
              claim.amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }})

            <a
              href="#"
              class="secondary-content cancel-item"
              @click.prevent="handleDelete(claim)"
              title="delete"
              ><i class="material-icons grey-text">cancel</i></a
            >

            <a
              href="#"
              class="secondary-content edit-item"
              @click.prevent="handleEdit(claim)"
              title="edit"
              ><i
                class="material-icons"
                :class="{
                  'grey-text': editId != claim.id,
                  'blue-text': editId == claim.id,
                }"
                >edit</i
              ></a
            >
          </li>
        </ol>

        <!-- Claims Submit -->
        <div v-if="claimsSubmitCard">
          <!-- Total -->
          <ul id="item-list" class="collection">
            <li class="collection-item pull-right">
              <b>Total</b> (Sent Amount):
              <b
                ><span class="red-text">₦{{ totalSent(claimsList) }}</span></b
              >
            </li>
          </ul>

          <br />

          <!-- Submit -->
          <a
            class="waves-effect waves-light btn-large green right"
            :class="{ pulse: submitPulse }"
            @click.prevent="handleSubmit()"
            ><i class="large material-icons right" 
                :class="{'disabled': isLoading}">check_circle</i>Okay
          </a>
          <a
            class="waves-effect waves-light btn-large black"
            @click.prevent="handleNext('moreClaims')"
            ><i class="medium material-icons left">add</i>Add</a
          >
        </div>
      </div>
    </div>

    
  </template>


  </div>
</div>
</template>
    

<script>
import { ref, onMounted, computed } from "vue";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import AddManualClaim from "@/components/claim/AddManualClaimComponent.vue";

export default {
  name: "EnrolleeClaimComponent",
  props: ["cluster", "reload"],
  emits: ["next"],
  components: { AddManualClaim },
  setup(props, context) {
    const isLoading = ref(false);
    const response = ref(null);
    const enrolleeId = ref(null);
    const encounterDate = ref(null);
    const error = ref(false);
    const editId = ref(null);
    const editClaim = ref(null);
    const deleteClaim = ref(null);
    const submitPulse = ref(false);
    const claimsDisplayCard = ref(false);
    const claimsSubmitCard = ref(false);
    const diagnosisList = ref([]);
    const resetDiagnosis = ref(false);
    const type = ref("claim"); // keep claim open after input
    const next = ref("next");
    const claimsList = ref([]);
    const claim = ref(null);

     // start
     onMounted(() => {
      // check reload
      if (props.reload) {
        // re-set
       response.value = props.reload;
      }
    });

    // get function
    const getSearchItemsFromApi = (table) => {
      (async () => {
        const { res } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = "Enrollee ID not found in Scheme!";
          response.value = null; // clear standing response
        }

        // page is ready, loading is complete
        isLoading.value = false;
      })();
    };

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // search
    const handleSearch = () => {
      // loading starts
      isLoading.value = true;
      // search
      getSearchItemsFromApi(`enrollee/search?q=${enrolleeId.value.trim()}`);
    };

    // search
    const handleDate = () => {
      // Create a new Date object
      var date = new Date(
        Date.UTC(
          props.cluster.year.search,
          props.cluster.month.search - 1,
          encounterDate.value,
          0,
          0,
          0
        )
      );

      // Check validity of Date
      if (
        date.getFullYear() == props.cluster.year.search &&
        date.getMonth() == props.cluster.month.search - 1 &&
        date.getDate() == encounterDate.value
      ) {
        // Format the date
        var formattedDate = date.toISOString().slice(0, 19).replace("T", " ");

        // Set encounter date
        response.value.encounter_date = formattedDate;
        // Set end date
        response.value.encounter_date_end = formattedDate;
      } else {
        error.value = "Date is Invalid!";
      }
    };

    // haandle clear
    const handleClearID = (val) => {
      if (val == "id") {
        enrolleeId.value = null;
        response.value = null;
        error.value = false;
      }

      if (val == "date") {
        encounterDate.value = null;
        error.value = false;
      }
    };

    // add up
    const totalSent = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        total = Number(total) + Number(x.amt);
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // handle Emitted update
    const handleUpdate = (val) => {
      claimsDisplayCard.value = val.claimsDisplayCard;
      claimsSubmitCard.value = val.claimsSubmitCard;
      claimsList.value = [...val.claimsList];
      diagnosisList.value = [...val.diagnosisList];
      submitPulse.value = val.submitPulse;
    };

    // handle edit
    const handleEdit = (val) => {
      claim.value = { action: "edit", claim: val };
      editId.value = val.id;
    };

    // handle edit
    const handleDelete = (val) => {
      claim.value = { action: "delete", claim: val };
    };

    // handle remove (passed as props)
    const handleReviewDiagnosis = () => {
      resetDiagnosis.value = true;
    };

    // handle next
    const handleNext = (val) => {
      next.value = val;
    };

    // handle clear (emiited)
    const handleClear = (val) => {
      if (val.id == "resetDiagnosis") {
        resetDiagnosis.value = val.value;
      } else if (val.id == "claim") {
        claim.value = val.value;
      } else if (val.id == "next") {
        next.value = val.value;
      } else if (val.id == "editId") {
        editId.value = val.value;
      }
    };

    const handleSubmit = () => {
      // start
      isLoading.value = true;

      if (
        !claims.value || 
        !enrollee.value
      ) {
        error.value = "Enrollee and/or Claims not completed"; // most unlikely
        // end
        isLoading.value = false;
      } else {
        let encounter = enrollee.value;
        encounter.editId = Date.now(); // unique ref if edit is required
        encounter.diagnosis = diagnosisList.value.join(", ");
        encounter.claims = claims.value;
        encounter.status = 'c'; // c=completed, p=pending
        setTimeout(function () {
          context.emit("next", {
            encounter: encounter,
          });
          // end
          isLoading.value = false;
        }, 1000);
      }
    };

    // output results
    const enrollee = computed(() => {
      if (!response.value) {
        return null;
      } else {
        return response.value;
      }
    });

    // output claims results
    const claims = computed(() => {
      if (!claimsList.value) {
        return [];
      } else {
        return claimsList.value;
      }
    });


    return {
      error,
      isLoading,
      showAgeSex,
      handleSearch,
      handleDate,
      handleClearID,
      handleEdit,
      handleReviewDiagnosis,
      handleDelete,
      handleUpdate,
      handleNext,
      handleSubmit,
      handleClear,
      totalSent,
      editId,
      claimsDisplayCard,
      claimsSubmitCard,
      resetDiagnosis,
      diagnosisList,
      editClaim,
      deleteClaim,
      submitPulse,
      claimsList,
      next,
      type,
      claim,
      claims,
      enrolleeId,
      enrollee,
      encounterDate,
    };
  },
};
</script>
    
<style scoped>
.progress {
  margin-top: -10px; /* Adjust the value as needed to move the progress bar upwards */
}

.counter {
  display: block;
  border-radius: 50%; /* Creates a circular shape */
  background-color: #f2f3f4; /* Grey background color */
  padding: 0.5em; /* Adjust padding to your liking */
  /* font-size: 1em;  Adjust font size */
}

.spacer5 {
  padding-top: 5px; /* Sets the padding of 20 pixels for the top */
  padding-bottom: 5px; /* Sets the padding of 20 pixels for the bottom */
  /* or shorthand */
  /* padding: 20px 0;  Sets the padding of 20 pixels for the top and bottom, 0 for left and right */
}

.spacer10 {
  padding-top: 10px; /* Sets the padding of 20 pixels for the top */
  padding-bottom: 10px; /* Sets the padding of 20 pixels for the bottom */
  /* or shorthand */
  /* padding: 20px 0;  Sets the padding of 20 pixels for the top and bottom, 0 for left and right */
}
</style>