<template>
  <div class="grey-bg-custom">
  <div class="container">
    <div class="row">
      <div class="col s12">
        <!-- <h1 class="teal-text">
          <i class="medium material-icons">filter_5</i> Providers
          <small>(Base-Tariff)</small>
        </h1> -->
      </div>
    </div>
    <div class="row" v-if="counter.length">
      <div class="col s12">
        <!-- Added -->
        <div class="card-panel" v-for="(count, id) in counter" :key="count.id">
          <div class="row">
            <div class="col s1">
              <span class="chip grey darken-2 white-text">{{ id + 1 }}</span>
            </div>
            <div class="col s9">
              <h5>
                {{ count.tariff }}
              </h5>
              <p>
                (<b>Total</b>) {{ count.value }} providers now using this
                <em>base</em> tariff
                <i class="material-icons clickable brown-text" title="view all"
                  >visibility</i
                >
              </p>
            </div>
            <div class="col s2">
              <i class="material-icons teal-text">check_circle</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="section">
          <form @submit.prevent="handleSubmit">
            <div class="card-panel">
              <div class="file-field input-field">
                <div class="btn black">
                  <span>Provider (List)</span>
                  <input type="file" @change="handleFile" />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" v-model="inputFile" type="text" />
                  <p class="red-text" v-if="fileError">{{ fileError }}</p>
                </div>
              </div>
            </div>

            <div class="card-panel">
              <div class="input-field">
                <select v-model="schedule">
                  <option value="" disabled selected>Select Tariff Type</option>
                  <option value="2">LifeBlue</option>
                  <option value="1">LifeOrange</option>
                  <option value="4">LifeGreen</option>
                </select>
                <label>Type of Tariff (<em>For all</em>)</label>
              </div>
            </div>

            <p v-if="uploadError"><br />{{ uploadError }}</p>

            <p>
              <br />
              <button
                class="waves-effect waves-light btn-large"
                :class="{
                  disabled: !file || !schedule || isSubmitting,
                }"
              >
                <i class="material-icons right">upload</i
                >{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload" }}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
    <template v-if="!next">
      <div class="section right">
        <button class="waves-effect waves-light btn-large" @click="handleNext">
          <i class="material-icons right">call_made</i>Next
        </button>
        <p>
          <br />
          <br />
        </p>
      </div>
    </template>
  </div>
</div>
</template>
      
 <script>
import { onMounted, ref, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import M from "materialize-css";

export default {
  name: "ProviderSetupComponent",
  emits: ["next"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isSubmitting = ref(false);
    const next = ref(false);
    const counter = ref([]);
    const schedule = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    counter.value.push({
          tariff: "LifeBlue Tariff",
          value: 24,
        });

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // drop down
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
    };

    // file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    const handleSubmit = async () => {
      if (file.value && schedule.value) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "providerLoader";

        // Round up
        const cloudInput = {
          schedule: schedule.value,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // clear all
        clearForm();
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        //do stuff...
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        // emit new parameters
        counter.value.push({
          tariff: "LifeBlue",
          value: data.value.succ.inserted_rows,
        });

        // clear all
        clearForm();
      }
      if (uploadError.value) {
        // clear all
        clearForm();
      }
    });

    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 5,
        isComplete: true,
      });
    };

    return {
      handleFile,
      handleNext,
      handleSubmit,
      next,
      counter,
      isSubmitting,
      schedule,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
    };
  },
};
</script>
      
<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.hover-text {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
</style>