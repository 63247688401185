<template>
  <LoadingDiv v-if="!isReady" />

  <div v-else>
    <div v-if="encounter">
      <div class="section">
        <div class="card">
          <span class="new badge left" data-badge-caption="Scheme">{{
            encounter.scheme.name.toUpperCase()
          }}</span>

          <div class="card-content">
            <div class="row">
              <div class="col s8">
                <p>{{ encounter.client_name }}</p>
                <h5>
                  {{ encounter.name }}
                  <sup
                    ><small
                      ><span class="black-text">{{
                        showAgeSex(encounter.dob, encounter.sex)
                      }}</span></small
                    ></sup
                  >
                </h5>
                <h6>({{ encounter.enrollee_number }})</h6>
              </div>
              <div class="col s4">
                <img
                  :src="encounter.profile_img"
                  width="96"
                  class="circle responsive-img right"
                  v-if="encounter.profile_img"
                />
                <img
                  :src="`https://ui-avatars.com/api/?name=${encounter.name}&size=128`"
                  width="96"
                  class="circle responsive-img right"
                  v-else
                />
              </div>
            </div>
          </div>

          <!-- Entire Provider (diagnosis, claims) Display starts here -->
          <div class="card grey lighten-4">
            <!-- Provider Name -->
            <div
              class="card-content grey lighten-4"
              v-if="'provider_id' in encounter"
            >
              <div class="row spacerTop10">
                <div class="col s12">
                  <div class="spacer5">
                    <span
                      class="card-title"
                      v-if="selectedProvider && selectedProvider.name"
                      >{{ selectedProvider.name }}

                      <a href="#" class="secondary-content orange-text right"
                        ><i
                          class="material-icons teal lighten-4"
                          title="clear"
                          @click.prevent="handleRestart"
                          >clear</i
                        ></a
                      >
                    </span>

                    <h6 class="teal-text">
                      {{
                        new Date(serverTime).toLocaleString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <!-- Provider Form -->
            <div
              class="card-panel teal lighten-5"
              v-if="!('provider_id' in encounter)"
            >
              <div class="row">
                <div class="col s12">
                  <span class="card-title black-text">Provider</span>
                </div>
                <div class="col s9">
                  <div class="input-field">
                    <FilterSimpleDiv
                      inputId="filterProvider"
                      fieldName="Select Provider"
                      :data="filterProviderData"
                      :search="true"
                      @selected="handlePick"
                      @searching="handleSearch"
                    />
                  </div>
                </div>
                <div class="col s3">
                  <p>
                    <button
                      class="waves-effect waves-light btn-large right"
                      :class="{
                        disabled: !selectedProvider || isLoading,
                      }"
                      @click="handleProvider"
                    >
                      <i class="material-icons right" v-if="!isLoading"
                        >chevron_right</i
                      >{{ isLoading ? "Adding..." : "Add" }}
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <!-- New -->
            <template v-else>
              <!-- Display Diagnosis -->
              <div class="row teal lighten-5" v-if="diagnosisList.length">
                <div class="col s12">
                  <div class="card-content yellow lighten-1">
                    <span class="black-text text-lighten-5">
                      <p>
                        <i class="material-icons black-text">bookmark_border</i>
                        Diagnosis: {{ diagnosisList.join(", ") }}
                        <a
                          href="#"
                          class="secondary-content edit-black-item right"
                          ><i
                            class="material-icons"
                            :class="{
                              'black-text': !resetDiagnosis,
                              'blue-text': resetDiagnosis,
                            }"
                            title="edit diagnosis"
                            @click.prevent="handleReviewDiagnosis"
                            >edit</i
                          ></a
                        >
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Claims Display Card -->
              <div class="card" v-if="claimsDisplayCard">
                <div class="card-content">
                  <span class="card-title">Item(s)</span>

                  <!-- List claims -->
                  <ol id="item-list" class="collection">
                    <li
                      class="collection-item"
                      v-for="claim in claims"
                      :key="claim.id"
                    >
                      <b>{{ claim.claim }}</b> x {{ claim.qty }} (₦{{
                        claim.amt
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}) --> (Esy-Jolad-56879)

                      <a
                        href="#"
                        class="secondary-content cancel-item"
                        @click.prevent="handleDelete(claim)"
                        title="delete"
                        ><i class="material-icons grey-text">cancel</i></a
                      >

                      <a
                        href="#"
                        class="secondary-content edit-item"
                        @click.prevent="handleEdit(claim)"
                        title="edit"
                        ><i
                          class="material-icons"
                          :class="{
                            'grey-text': editId != claim.id,
                            'blue-text': editId == claim.id,
                          }"
                          >edit</i
                        ></a
                      >
                    </li>
                  </ol>

                  <!-- Claims Submit -->
                  <div v-if="claimsSubmitCard">
                    <!-- Total -->
                    <ul id="item-list" class="collection">
                      <li class="collection-item pull-right">
                        <b>Total</b> (Sent Amount):
                        <b
                          ><span class="red-text"
                            >₦{{ totalSent(claimsList) }}</span
                          ></b
                        >
                      </li>
                    </ul>

                    <br />

                    <!-- Submit -->
                    <a
                      class="waves-effect waves-light btn-large green right"
                      :class="{
                        pulse: submitPulse && !isSubmitting,
                        disabled: isSubmitting,
                      }"
                      @click.prevent="handleSubmit()"
                      ><i class="large material-icons right">check_circle</i
                      >{{ isSubmitting ? "Authorizing ..." : "Authorize" }}
                    </a>
                    <a
                      class="waves-effect waves-light btn-large black"
                      :class="{ disabled: isSubmitting }"
                      @click.prevent="handleNext('moreClaims')"
                      ><i class="medium material-icons left">add</i>Add</a
                    >
                  </div>
                </div>
              </div>

              <!-- Add claim -->
              <AddClaim
                :type="type"
                :next="next"
                :editDiagnosis="resetDiagnosis"
                :claimItem="claim"
                @update="handleUpdate"
                @clear="handleClear"
              />
            </template>

            <!-- History -->
            <HistoryAuth :enrolleeNumber="encounter.enrollee_number" />
          </div>
        </div>
      </div>
    </div>

    <div class="section center" v-if="!encounter">
      <h6>No Encounter Record for Enrollee <em>found</em>...</h6>
    </div>
  </div>
</template>
  
<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import AddClaim from "@/components/claim/AddClaimComponent.vue";
import HistoryAuth from "@/components/auth/HistoryAuthComponent.vue";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "EnrolleeAuthComponent",
  props: ["enrollee", "serverTime"],
  emits: ["restart"],
  components: { LoadingDiv, FilterSimpleDiv, AddClaim, HistoryAuth },
  setup(props, context) {
    const response = ref(null);
    const responseSearch = ref(null);
    const selectedItem = ref(null);
    const selectedProvider = ref(null);
    const isReady = ref(true);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(false);
    const editId = ref(null);
    const editClaim = ref(null);
    const deleteClaim = ref(null);
    const submitPulse = ref(false);
    const claimsDisplayCard = ref(false);
    const claimsSubmitCard = ref(false);
    const diagnosisList = ref([]);
    const resetDiagnosis = ref(false);
    const type = ref("auth"); // close claim after input
    const next = ref("next");
    const claimsList = ref([]);
    const claim = ref(null);

    // router
    const router = useRouter();

    // Provider
    const filterProviderData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterProvider") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responseSearch.value = { key: key, value: res.value.data };
        }
      })();
    };

    // add up
    const totalSent = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        total = Number(total) + Number(x.amt);
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // get started
      response.value = props.enrollee;
    });

    // filter components where search is set to "true"
    const handleSearch = (val) => {
      if (val.id == "filterProvider") {
        // get new serach items
        getSearchItemsFromApi(`provider/filter?q=${val.value}`, val.id);
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterProvider") {
          //set
          if (!selectedItem.value.value) {
            selectedProvider.value = null;
          } else {
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedProvider.value = { name: x.name, search: x.id };
              }
            });
          }
        }
      }
    });

    // handle provider
    const handleProvider = () => {
      // Set provider
      response.value.provider_id = selectedProvider.value.search;
    };

    // handle Emitted update
    const handleUpdate = (val) => {
      claimsDisplayCard.value = val.claimsDisplayCard;
      claimsSubmitCard.value = val.claimsSubmitCard;
      claimsList.value = [...val.claimsList];
      diagnosisList.value = [...val.diagnosisList];
      submitPulse.value = val.submitPulse;
    };

    // handle edit
    const handleEdit = (val) => {
      claim.value = { action: "edit", claim: val };
      editId.value = val.id;
    };

    // handle edit
    const handleDelete = (val) => {
      claim.value = { action: "delete", claim: val };
    };

    // handle remove (passed as props)
    const handleReviewDiagnosis = () => {
      resetDiagnosis.value = true;
    };

    // handle next
    const handleNext = (val) => {
      next.value = val;
    };

    // handle clear (emiited)
    const handleClear = (val) => {
      if (val.id == "resetDiagnosis") {
        resetDiagnosis.value = val.value;
      } else if (val.id == "claim") {
        claim.value = val.value;
      } else if (val.id == "next") {
        next.value = val.value;
      } else if (val.id == "editId") {
        editId.value = val.value;
      }
    };

    // hanlde restart
    const handleRestart = () => {
      context.emit("restart", null);
    };

    // handle final submit
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;

      // setup encounter
      let diagnosis = diagnosisList.value.join(", ");
      let keysToRemove = ["id", "scheme", "created_at", "updated_at"];
      let newEncounter = {
        ...Object.fromEntries(
          Object.entries(encounter.value).filter(
            ([key]) => !keysToRemove.includes(key)
          )
        ),
      };
      newEncounter = {
        ...newEncounter,
        encounter_cluster_id: null,
        diagnosis: diagnosis,
        encounter_date: props.serverTime,
        encounter_date_end: null,
      }; // the server will sort the encounters nulls

      // setup claims
      let newClaims = [];
      let newAuth = "HMO-CODE";

      claims.value.forEach((x, y) => {
        newAuth = `${newAuth}-${++y}`;
        newClaims.push({
          encounter_id: null,
          item: x.claim,
          sent_qty: x.amt,
          sent_price: x.qty,
          standard_price: x.standard,
          authorization: "y",
          authorization_code: newAuth,
        });
      });

      // put all together
      let newVal = { encounter: newEncounter, claims: newClaims };

      // post item
      const { res, err } = await postItem("claim/encounter/bulk", newVal);

      if (res.value) {
        // final end
        router.push({ name: "auth" });
      } else {
        error.value = err.value;
        isSubmitting.value = false; // end
      }
    };

    // output encounter results
    const encounter = computed(() => {
      if (!response.value) {
        return null;
      } else {
        return response.value;
      }
    });

    // output claims results
    const claims = computed(() => {
      if (!claimsList.value) {
        return [];
      } else {
        return claimsList.value;
      }
    });

    return {
      showAgeSex,
      handleProvider,
      handleEdit,
      handleReviewDiagnosis,
      handleDelete,
      handleUpdate,
      handleNext,
      handleRestart,
      handleSubmit,
      handleClear,
      handleSearch,
      handlePick,
      selectedItem,
      filterProviderData,
      selectedProvider,
      totalSent,
      isReady,
      isLoading,
      isSubmitting,
      error,
      editId,
      claimsDisplayCard,
      claimsSubmitCard,
      resetDiagnosis,
      diagnosisList,
      editClaim,
      deleteClaim,
      encounter,
      submitPulse,
      claimsList,
      next,
      type,
      claim,
      claims,
    };
  },
};
</script>
  
<style scoped>
.progress {
  margin-top: -10px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer5 {
  padding-top: 5px; /* Sets the padding of 20 pixels for the top */
  padding-bottom: 5px; /* Sets the padding of 20 pixels for the bottom */
  /* or shorthand */
  /* padding: 20px 0;  Sets the padding of 20 pixels for the top and bottom, 0 for left and right */
}

.spacerTop10 {
  padding-top: 10px; /* Sets the padding of 20 pixels for the top */
  padding-bottom: 0px; /* Sets the padding of 20 pixels for the bottom */
  /* or shorthand */
  /* padding: 20px 0;  Sets the padding of 20 pixels for the top and bottom, 0 for left and right */
}

.edit-black-item:hover .material-icons.black-text,
.edit-black-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>
    