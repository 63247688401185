<template>
  <div class="row">
    <div class="col s12">
      <LoadingDiv v-if="!isReady" />

      <div class="section" v-else>
        <!-- Claims details -->
        <div class="card-panel">
          <div class="row">
            <div class="col s1">#</div>
            <div class="col s3">Provider <small>(claims)</small></div>
            <div class="col s2">Sent</div>
            <div class="col s2">Approved</div>
            <div class="col s3">Claims <small>(scheme)</small></div>
            <!-- <div class="col s1">Flag</div> -->
            <div class="col s1"><br /></div>
          </div>
        </div>

        <div v-for="(cluster, index) in clusters" :key="cluster.id">
          <div
            class="card-panel"
            :class="{
              'blue-grey lighten-5': (index + 1) % 2 === 1,
            }"
            v-if="currCluster == cluster || !currCluster"
          >
            <div class="row">
              <div class="col s1">
                <p>{{ numberBadge(index) }}</p>
              </div>
              <div class="col s3">
                <h6>
                  <span
                    v-if="
                      cluster.encounters.filter((x) => x.compliance_flag == 'y')
                        .length
                    "
                    ><i
                      class="tiny material-icons red-text pointer"
                      title="compliance issue"
                      >flag</i
                    ></span
                  >
                  {{ cluster.provider.name }}
                  <br />
                  <span v-if="cluster.status !== 'q'"
                    ><small
                      >({{ cluster.encounters.length }} Claims)</small
                    ></span
                  >
                </h6>
              </div>
              <div class="col s2">
                <p>
                  <span class="red-text" v-if="cluster.status === 'q'"
                    >₦0.00</span
                  >
                  <span class="red-text" v-else
                    >₦{{ grossTotalSent(cluster.encounters) }}</span
                  >
                </p>
              </div>
              <div class="col s2">
                <p>
                  <span class="chip" v-if="cluster.status === 'p'"
                    >Unvetted</span
                  >
                  <span class="chip yellow" v-if="cluster.status === 'q'"
                    >Unsubmitted</span
                  >
                  <span
                    class="green-text"
                    v-if="cluster.status === 'r' || cluster.status === 'v'"
                    ><b>₦{{ grossTotalSent(cluster.encounters) }}</b></span
                  >
                </p>
              </div>
              <div class="col s3">
                <p>
                  {{ toMonthYear(cluster.month_year) }}
                  <small
                    ><b>({{ cluster.scheme.name }})</b></small
                  >
                </p>
              </div>
              <!-- <div class="col s1">
              <p>
                <span
                  class="chip green"
                  v-if="checkPaid(cluster.payment, cluster.repayment)"
                  >Paid</span
                >
                <span v-else>{{
                  paymentStatus(
                    cluster.status,
                    cluster.payment,
                    cluster.repayment
                  )
                }}</span>
              </p>
            </div> -->
              <div class="col s1">
                <span v-if="cluster.status === 'q'">
                  <span class="btn-floating black pointable right"
                    ><i class="material-icons" title="locked"
                      >lock_outline</i
                    ></span
                  >
                </span>

                <span v-if="cluster.status === 'p'">
                  <a class="btn-floating red right"
                    ><i
                      class="material-icons"
                      title="review"
                      @click="handleView(cluster)"
                      >chevron_right</i
                    ></a
                  >
                </span>

                <span
                  v-if="
                    (cluster.status === 'r' || cluster.status === 'v') &&
                    !currCluster
                  "
                >
                  <a
                    class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                    ><i
                      class="material-icons"
                      @click="handleView(cluster)"
                      title="details"
                      >chevron_right</i
                    ></a
                  >
                </span>
                <span class="right" v-else>
                  <a
                    class="btn-floating btn-medium waves-effect waves-light light-blue right"
                    ><i
                      class="material-icons"
                      @click="handleClear()"
                      title="go back"
                      >keyboard_return</i
                    ></a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Cluster details -->
        <ClaimDiv
          :clusterId="currCluster.id"
          @reset="handleReset"
          v-if="currCluster"
        />

        <!-- Pages -->
        <template v-else>
          <div class="section center" v-if="!clusters.length">
            <h6>No Claims <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </template>
      </div>
    </div>
  </div>
</template>
            
<script>
import { computed, onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import ClaimDiv from "@/components/compliance/ComplianceSingleClaimComponent.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "ComplianceClaimsComponent",
  components: { LoadingDiv, PaginationDiv, ClaimDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currCluster = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      let total = 0;
      encounters.forEach((z) => {
        z.claims.forEach((x) => {
          if (x.sent_price) {
            total = Number(total) + Number(x.sent_price);
          }
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      let parts = val.split("_");
      let month = parseInt(parts[0]);
      let year = parseInt(parts[1]);
      let date = new Date(year, month - 1); // months are zero-indexed in JavaScript
      let monthName = date.toLocaleString("default", { month: "short" });
      return monthName + " " + year;
    };

    // payment status
    const checkPaid = (payment, repayment) => {
      let val = false;

      if (payment) {
        if (payment.stage == "c") {
          val = true;
        }
      }

      if (repayment) {
        if (repayment.stage == "c") {
          val = true;
        }
      }

      return val;
    };

    // payment status
    const paymentStatus = (status, payment, repayment) => {
      let val = "N/A";
      if (payment) {
        if (payment.stage == "p") {
          val = `(Batched #${payment.id})`;
        } else if (payment.stage == "q") {
          val = `(Queued #${payment.id})`;
        } else if (payment.stage == "c") {
          val = `(Paid #${payment.id})`;
        }
      } else {
        if (status == "v") {
          val = "Pending Batching";
        }
      }

      if (repayment) {
        if (repayment.stage == "p") {
          val = `(Batched #${repayment.id})`;
        } else if (repayment.stage == "q") {
          val = `(Queued #${repayment.id})`;
        } else if (repayment.stage == "c") {
          val = `(Paid #${repayment.id})`;
        }
      } else {
        if (status == "r") {
          val = "Pending Batching";
        }
      }

      return val;
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // get started
      getPendingClaims();
    });

    // get fresh load
    const getPendingClaims = () => {
      // set current Url
      currUrl.value = "cluster/compliance/vetted";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // update cluster
    const handleReset = (val) => {
      if (val != "reload") {
        response.value.data.forEach((x, y) => {
          if (x.id == val.clusterId) {
            x.encounters.forEach((z, k) => {
              if (z.id == val.encounterId) {
                response.value.data[y].encounters[k] = {
                  ...z,
                  compliance_flag: val.newFlag,
                  compliance_comment: val.newComment,
                  status: val.newStatus,
                };
              }
            });
          }
        });
      } else {
        // compliance completed (reload page)
        getPendingClaims();
        // kill currCluster
        currCluster.value = null;
      }
    };

    // enrollee ction
    const handleClear = () => {
      currCluster.value = null;
    };

    // enrollee ction
    const handleView = (val) => {
      currCluster.value = val;
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const clusters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data; // get data portion
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });


    return {
      numberBadge,
      grossTotalSent,
      toMonthYear,
      checkPaid,
      paymentStatus,
      handleReset,
      handlePage,
      handleClear,
      handleView,
      currServerDate,
      currCluster,
      isReady,
      isLoading,
      error,
      clusters,
      allPages,
    };
  },
};
</script>
            
<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>
    