import { createRouter, createWebHistory } from 'vue-router'

// Utility
import UtilityHome from '../views/utility/UtilityHome.vue'

// Login
import LoginHome from '../views/login/LoginHome.vue'

// Loader
import LoaderWait from '../views/login/LoaderWait.vue'

// Setup
import SetupHome from '../views/setup/SetupHome.vue'

// Settings
import SettingsHome from '../views/settings/SettingsHome.vue'

// Home
import HomeView from '../views/HomeView.vue'

// Adders
import AdderHome from '../views/adder/AdderHome.vue'

// Bypass
import BypassHome from '../views/bypass/BypassHome.vue'

// Client
import ClientHome from '../views/client/ClientHome.vue'
import ClientView from '../views/client/ClientView.vue'

// Private
import PrivateHome from '../views/private/PrivateHome.vue'
import PrivateView from '../views/private/PrivateView.vue'
import PrivateAdd from '../views/private/PrivateAdd.vue'

// Public
import PublicHome from '../views/public/PublicHome.vue'
import PublicAdd from '../views/public/PublicAdd.vue'

// Retail
import RetailHome from '../views/retail/RetailHome.vue'
import RetailView from '../views/retail/RetailView.vue'

// TPA
import TPAHome from '../views/tpa/TPAHome.vue'
import TPAView from '../views/tpa/TPAView.vue'
import TPAAdd from '../views/tpa/TPAAdd.vue'

// Provider
import ProviderHome from '../views/provider/ProviderHome.vue'
import ProviderView from '../views/provider/ProviderView.vue'
import ProviderAdd from '../views/provider/ProviderAdd.vue'

// Provider
import HospitalHome from '../views/hospital/HospitalHome.vue'

// Claim
import ClaimHome from '../views/claim/ClaimHome.vue'
import ClaimView from '../views/claim/ClaimView.vue'
import ClaimAdd from '../views/claim/ClaimAdd.vue'

// Auth
import AuthHome from '../views/auth/AuthHome.vue'
import AuthView from '../views/auth/AuthView.vue'
import AuthAdd from '../views/auth/AuthAdd.vue'

// Advisory
import AdvisoryHome from '../views/advisory/AdvisoryHome.vue'

// Advisory
import ComplianceHome from '../views/compliance/ComplianceHome.vue'

// Finance
import FinanceHome from '../views/finance/FinanceHome.vue'

// Enrollee
import EnrolleeHome from '../views/enrollee/EnrolleeHome.vue'

// Payment
import PaymentHome from '../views/payment/PaymentHome.vue'

// Report
import ReportHome from '../views/report/ReportHome.vue'

// authentication routing
import store from '../store/index'

// Routing guide 1
const authRequired = (to, from, next) => {

  const user = store.state.user;

  if (user) {
    // Move on
    next();
  } else {
    // Redirect
    next({ name: 'login' })
  }

}


const routes = [
  {
    path: '/utility/view/:clientId/:stampId',
    name: 'utility',
    component: UtilityHome,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginHome
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoaderWait,
    beforeEnter: authRequired
  },
  {
    path: '/setup',
    name: 'setup',
    component: SetupHome,
    beforeEnter: authRequired
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: authRequired
  },
  {
    path: '/adder',
    name: 'adder',
    component: AdderHome,
    beforeEnter: authRequired
  },
  {
    path: '/bypass',
    name: 'bypass',
    component: BypassHome,
    beforeEnter: authRequired
  },
  {
    path: '/client',
    name: 'client',
    component: ClientHome,
    beforeEnter: authRequired
  },
  {
    path: '/client/view/:id',
    name: 'clientView',
    component: ClientView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/private',
    name: 'private',
    component: PrivateHome,
    beforeEnter: authRequired
  },
  {
    path: '/private/view/:id',
    name: 'privateView',
    component: PrivateView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/private/add',
    name: 'privateAdd',
    component: PrivateAdd,
    beforeEnter: authRequired
  },
  {
    path: '/public',
    name: 'public',
    component: PublicHome,
    beforeEnter: authRequired
  },
  {
    path: '/public/add/:id',
    name: 'publicAdd',
    component: PublicAdd,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/retail',
    name: 'retail',
    component: RetailHome,
    beforeEnter: authRequired
  },
  {
    path: '/retail/view/:id',
    name: 'retailView',
    component: RetailView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/tpa',
    name: 'tpa',
    component: TPAHome,
    beforeEnter: authRequired
  },
  {
    path: '/tpa/view/:id',
    name: 'tpaView',
    component: TPAView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/tpa/add',
    name: 'tpaAdd',
    component: TPAAdd,
    beforeEnter: authRequired
  },
  {
    path: '/hospital',
    name: 'hospital',
    component: HospitalHome,
    beforeEnter: authRequired
  },
  {
    path: '/provider',
    name: 'provider',
    component: ProviderHome,
    beforeEnter: authRequired
  },
  {
    path: '/provider/view/:id',
    name: 'providerView',
    component: ProviderView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/provider/add',
    name: 'providerAdd',
    component: ProviderAdd,
    beforeEnter: authRequired
  },
  {
    path: '/claim',
    name: 'claim',
    component: ClaimHome,
    beforeEnter: authRequired
  },
  {
    path: '/claim/view/:clusterId',
    name: 'claimView',
    component: ClaimView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/claim/add/',
    name: 'claimAdd',
    component: ClaimAdd,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthHome,
    beforeEnter: authRequired
  },
  {
    path: '/auth/view/:enrolleeId/:claimId/:encounterId',
    name: 'authView',
    component: AuthView,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/auth/add',
    name: 'authAdd',
    component: AuthAdd,
    beforeEnter: authRequired
  },
  {
    path: '/enrollee',
    name: 'enrollee',
    component: EnrolleeHome,
    beforeEnter: authRequired
  },
  {
    path: '/advisory',
    name: 'advisory',
    component: AdvisoryHome,
    beforeEnter: authRequired
  },
  {
    path: '/compliance',
    name: 'compliance',
    component: ComplianceHome,
    beforeEnter: authRequired
  },
  {
    path: '/finance',
    name: 'finance',
    component: FinanceHome,
    beforeEnter: authRequired
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentHome,
    beforeEnter: authRequired
  },
  {
    path: '/report',
    name: 'report',
    component: ReportHome,
    beforeEnter: authRequired
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsHome,
    beforeEnter: authRequired
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
