<template>
  <LoadingDiv v-if="isLoading" />

  <div v-else>
    <template v-if="encounter">
      <div class="card">
        <!-- Bio -->
        <div
          class="card-panel"
          :class="{ 'red lighten-5': encounter.status == 'd' }"
        >
          <div class="row">
            <div class="col s1 grey lighten-4">
              <span class="chip light-blue lighten-5 center"
                ><b>{{ curr }}/{{ total }}</b></span
              >
            </div>
            <div class="col s7">
              <p>{{ encounter.client_name }}</p>
              <h5>{{ encounter.name }} ({{ encounter.enrollee_number }})</h5>
              <h6 class="red-text">
                Encounter on
                {{
                  new Date(encounter.encounter_date).toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </h6>
            </div>
            <div class="col s4">
              <img
                :src="encounter.profile_img"
                width="96"
                class="circle responsive-img right"
                v-if="encounter.profile_img"
              />
              <img
                :src="`https://ui-avatars.com/api/?name=${encounter.name}&size=128`"
                width="96"
                class="circle responsive-img right"
                v-else
              />
            </div>
          </div>

          <!-- Show flag -->
          <template v-if="encounter?.compliance_flag == 'y'">
            <div class="card-content spacer10">
              <div class="row yellow lighten-5">
                <div class="col s12 spacer10">
                  <label class="active orange-text darken-text-2"
                    ><i class="tiny material-icons">flag </i> Compliance
                    Flagged</label
                  >
                  <p>Kindly review claim for re-approval.</p>
                  <br />
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- Display Diagnosis -->
        <div class="row teal lighten-5">
          <div class="col s12">
            <div class="card-content yellow lighten-1">
              <span class="collections black-text">
                <h6>
                  <i class="tiny material-icons">bookmark_border</i>
                  Diagnosis: {{ encounter.diagnosis }}
                </h6>
              </span>
            </div>
          </div>
        </div>

        <!-- Claims Display Card -->
        <div class="card">
          <div class="card-content">
            <span class="card-title">Claims</span>

            <!-- List claims -->
            <ol id="item-list" class="collection">
              <li
                class="collection-item"
                v-for="claim in encounter.claims"
                :key="claim.id"
              >
                <!-- Approved Items -->
                <span v-if="claim.status == 'a'">
                  <b>{{ claim.item }}</b> x {{ claim.sent_qty }} (₦{{
                    Number(claim.sent_price)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }})

                  <!-- Reviewed claim -->
                  <span
                    class="brown-text"
                    v-if="claim.sent_price != claim.approved_price"
                  >
                    <b>
                      => (₦{{
                        Number(claim.approved_price)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }})<span
                        class="info-item tooltipped pointable"
                        data-position="top"
                        :data-tooltip="claim.comment"
                      >
                        <i class="material-icons grey-text">help</i>
                      </span></b
                    >
                  </span>

                  <!-- Authorizations (active) -->
                  <span v-if="claim.authorization == 'y'">
                    => ({{ claim.authorization_code }})
                    <span
                      class="info-item tooltipped secondary-content pointable"
                      data-position="top"
                      data-tooltip="Omorinmade"
                    >
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </span>

                  <!-- Actions -->
                  <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                    <span
                      v-if="
                        encounter.status != 'd' && claim.authorization != 'y'
                      "
                    >
                      <a
                        href="#"
                        class="secondary-content cancel-item"
                        @click.prevent="handleDelete(claim)"
                        title="delete"
                        ><i
                          class="material-icons"
                          :class="{
                            'grey-text': deleteClaim != claim,
                            'red-text': deleteClaim == claim,
                          }"
                          >cancel</i
                        ></a
                      >
                      <a
                        href="#"
                        class="secondary-content edit-item"
                        @click.prevent="handleEdit(claim)"
                        title="edit"
                        ><i
                          class="material-icons"
                          :class="{
                            'grey-text': editClaim != claim,
                            'blue-text': editClaim == claim,
                          }"
                          >edit</i
                        ></a
                      >
                    </span>
                  </template>
                </span>

                <!-- Deleted Items -->
                <span v-else>
                  <s
                    >{{ claim.item }} x {{ claim.sent_qty }} (Amount Sent: ₦{{
                      Number(claim.sent_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }})</s
                  >
                  <b
                    ><span
                      class="info-item tooltipped pointable"
                      data-position="top"
                      :data-tooltip="claim.comment"
                    >
                      <i class="material-icons grey-text">help</i>
                    </span></b
                  >

                  <!-- Authorizations (delete) -->
                  <span v-if="claim.authorization == 'y'">
                    => ({{ claim.authorization_code }})
                    <span
                      class="info-item tooltipped secondary-content pointable"
                      data-position="top"
                      data-tooltip="Omorinmade"
                    >
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </span>

                  <!-- Actions -->
                  <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                    <span
                      v-if="
                        encounter.status != 'd' && claim.authorization != 'y'
                      "
                    >
                      <a
                        href="#"
                        class="secondary-content reset-item"
                        @click.prevent="handleReset(claim)"
                        title="reset"
                        ><i class="small material-icons grey-text"
                          >refresh</i
                        ></a
                      >
                    </span>
                  </template>
                </span>
              </li>
            </ol>

            <!-- Claims Submit -->
            <div v-if="claimsSubmitCard">
              <!-- Total -->
              <ul id="item-list" class="collection">
                <li class="collection-item">
                  <span class="right">
                    <em>(Sent: ₦{{ totalSent(encounter.claims) }})</em> |

                    {{ encounter.status == "p" ? "Vetted" : "Approved" }}:
                    <b
                      ><span
                        :class="{
                          'red-text': encounter.status == 'p',
                          'green-text text-darken-2': encounter.status != 'p',
                        }"
                        >₦{{ totalApproved(encounter.claims) }}</span
                      ></b
                    >

                    <br />
                  </span>
                </li>
              </ul>

              <br />

              <!-- Submit -->
              <template v-if="clusterStatus != 'v' && clusterStatus != 'r'">
                <a
                  class="waves-effect waves-light btn-large right"
                  :class="{ green: encounter.status == 'p' }"
                  @click.prevent="handleNext('approve')"
                >
                  <i class="large material-icons right"
                    >{{
                      encounter.status == "p" ? "check_circle" : "chevron_right"
                    }} </i
                  >{{ encounter.status == "p" ? "Approve" : "Next" }}
                </a>
                <a
                  class="waves-effect waves-light btn-large black"
                  @click.prevent="handleEncounterForDelete(encounter)"
                  v-if="encounter.status != 'd'"
                  ><i class="medium material-icons left">clear</i>Reject</a
                >
                <a
                  class="waves-effect waves-light btn-large black"
                  @click.prevent="handleReVet(encounter)"
                  v-else
                  ><i class="medium material-icons left">refresh</i>Re-vet</a
                >
              </template>

              <template v-else>
                <a class="waves-effect waves-light btn-large disabled black"
                  ><i class="medium material-icons left">clear</i>Reject</a
                >

                <a
                  class="waves-effect waves-light btn-large right"
                  @click.prevent="handleBack"
                  ><i class="large material-icons right">chevron_right</i
                  >Okay</a
                >
              </template>
            </div>
          </div>
        </div>

        <!-- Edit Claims -->
        <div
          class="card"
          :class="{ 'teal lighten-5': !error, 'red lighten-5': error }"
          v-if="claimsCard"
        >
          <div class="card-content" v-if="deleteClaim">
            <form>
              <div class="row" v-if="deleteHolder">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment">Comment</label>
                </div>
              </div>
              <div class="row" v-else>
                <div class="input-field col s8">
                  <input v-model="item" id="item" type="text" readonly />
                  <label class="active" for="qty">Item</label>
                </div>
                <div class="input-field col s4">
                  <input v-model="amt" id="amt" type="number" readonly />
                  <label class="active" for="amt">Amount</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a
                    class="waves-effect waves-light btn black lighten-2"
                    @click.prevent="handleRefresh"
                  >
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button
                    class="waves-effect waves-light btn right"
                    :class="{ red: deleteHolder }"
                    @click.prevent="handleClaimDelete"
                  >
                    <i class="medium material-icons right">chevron_right</i>
                    {{ deleteHolder ? "Delete" : "Confirm Item" }}
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-content" v-if="editClaim">
            <form>
              <div class="row" v-if="editHolder">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment">Comment</label>
                </div>
              </div>
              <div class="row" v-else>
                <div class="input-field col s8">
                  <input v-model="item" id="item" type="text" readonly />
                  <label class="active" for="qty">Item</label>
                </div>
                <div class="input-field col s2 teal lighten-5">
                  <input
                    v-model="qty"
                    @input="handlePricing"
                    id="qty"
                    type="number"
                  />
                  <label class="active teal-text" for="qty">Quantity</label>
                </div>
                <div class="input-field col s2">
                  <input v-model="amt" id="amt" type="number" readonly />
                  <label class="active" for="amt">Amount (Approval)</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a
                    class="waves-effect waves-light btn black lighten-2"
                    @click.prevent="handleRefresh"
                  >
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button
                    class="waves-effect waves-light btn green right"
                    @click.prevent="handleClaimEdit"
                  >
                    <i class="medium material-icons right">chevron_right</i>
                    Okay
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-content" v-if="deleteEncounter">
            <form>
              <div class="row">
                <div class="input-field col s12">
                  <input v-model="comment" id="comment" type="text" />
                  <label class="active teal-text" for="comment"
                    >Comment (Rejection Reason)</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <a
                    class="waves-effect waves-light btn black lighten-2"
                    @click.prevent="handleRefresh"
                  >
                    <i class="medium material-icons left">chevron_left</i>Back
                  </a>

                  <button
                    class="waves-effect waves-light btn red right"
                    @click.prevent="handleEncounterDelete"
                  >
                    <i class="medium material-icons left">highlight_off</i>
                    Confirm Reject
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>

    <div class="section center" v-if="!encounter">
      <h6>No Claims Encounter <em>found</em>...</h6>
    </div>
  </div>
</template>
  
<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import postItem from "@/composables/postItem";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import { capitalizeFirstLetter } from "@/composables/fxLibrary/coreFx.js";
import M from "materialize-css";

export default {
  name: "VetClaimComponent",
  components: { LoadingDiv },
  props: ["clusterStatus", "cluster", "encounterId"],
  emits: ["vetted"],
  setup(props, context) {
    const response = ref(null);
    const responseAll = ref([]);
    const isLoading = ref(true);
    const error = ref(false);
    const curr = ref(0);
    const total = ref(0);
    const deleteClaim = ref(null);
    const deleteHolder = ref(false);
    const editClaim = ref(null);
    const editHolder = ref(false);
    const deleteEncounter = ref(null);
    const claimsCard = ref(false);
    const claimsDisplayCard = ref(true);
    const claimsSubmitCard = ref(true);
    const claimsList = ref([]);
    const item = ref(null);
    const qty = ref(null);
    const amt = ref(null);
    const standard = ref(null);
    const comment = ref(null);

    // toolTip
    const toolTip = () => {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {});
    };

    // restart toolTip
    const restartComments = () => {
      // load comment
      setTimeout(() => {
        toolTip();
      }, 2000);
    };

    // load after data loads and updates
    watchEffect(() => {
      if (response.value) {
        restartComments();
      }
    });

    // total calculations
    const totalSent = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        if (x.sent_price) {
          total = Number(total) + Number(x.sent_price);
        }
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // total calculations
    const totalApproved = (claims) => {
      let total = 0;
      claims.forEach((x) => {
        if (x.approved_price && x.status == "a") {
          total = Number(total) + Number(x.approved_price);
        }
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // next Unvetted
    onMounted(() => {
      if (props.cluster) {
        // start
        responseAll.value = props.cluster;
        // total
        total.value = responseAll.value.length;
        // next
        updateClusterData(responseAll.value);
      }
    });

    // starter
    const updateClusterData = (val) => {
      if (val) {
        // check if available for vetting
        val.forEach((x, y) => {
          if (x.id == props.encounterId) {
            // (seen) good to go
            response.value = x;
            // number
            curr.value = y + 1;
          }
        });
        if (!response.value) {
          // next
          handleNext("next");
        }
      }
      // end load
      isLoading.value = false;
    };

    // clear inputs
    const claimInputClear = () => {
      // hide show cards
      claimsCard.value = false;
      claimsSubmitCard.value = true;

      // clear all
      item.value = null;
      qty.value = null;
      amt.value = null;
      standard.value = null;
      comment.value = null;

      // clear delete (if needed)
      deleteClaim.value = null;
      // clear editHolder
      deleteHolder.value = false;
      // clear edit (if needed)
      editClaim.value = null;
      // clear editHolder
      editHolder.value = false;
      // clear encounter delete
      deleteEncounter.value = null;
      // clear error (if needed)
      error.value = false;

      // retart Comments
      restartComments();
    };

    // edit claim
    const handleEdit = (claim) => {
      if (claim != editClaim.value) {
        // clear all
        claimInputClear();

        // hide show cards
        claimsCard.value = true;
        claimsSubmitCard.value = false;

        // for main
        editClaim.value = claim;
        item.value = claim.item;
        qty.value = claim.sent_qty;
        amt.value = claim.sent_price;
        standard.value = claim.standard_price;
      } else {
        // same item was clicked twice
        // clear all
        claimInputClear();
      }
    };

    // delete claim
    const handleDelete = (claim) => {
      if (claim != deleteClaim.value) {
        // clear all
        claimInputClear();

        // hide show cards
        claimsCard.value = true;
        claimsSubmitCard.value = false;

        // for main
        deleteClaim.value = claim;
        item.value = claim.item;
        qty.value = claim.sent_qty;
        amt.value = claim.sent_price;
        standard.value = claim.standard_price;
      } else {
        // same item was clicked twice
        // clear all
        claimInputClear();
      }
    };

    // prepare delete encounter
    const handleEncounterForDelete = (encounter) => {
      // clear all
      claimInputClear();

      // hide show cards
      claimsCard.value = true;
      claimsSubmitCard.value = false;

      // for main
      deleteEncounter.value = encounter;
    };

    // re-vet
    const handleReVet = (encounter) => {
      // clear all
      claimInputClear();

      // restore
      if (response.value == encounter) {
        response.value.claims.forEach((x) => {
          // spare authorization = z, y
          if (x.authorization == "n") {
            x.approved_qty = null; // insurance (should be null already)
            x.approved_price = null; // insurance (should be null already)
            x.comment = null;
          }
          x.status = "a";
        });
        response.value.status = "p"; // switch back to pending
      }
    };

    // reset claim
    const handleReset = (claim) => {
      // edit
      const index = response.value.claims.findIndex((x) => x.id === claim.id);
      if (index !== -1) {
        // claim status = a = active
        response.value.claims[index] = {
          ...response.value.claims[index],
          status: "a",
        };
        // encounter status = p = pending vetting
        response.value.status = "p";

        // clear entry
        claimInputClear();
      }
    };

    // load claims
    const handleClaimDelete = () => {
      if (deleteHolder.value && comment.value) {
        // edit
        const index = response.value.claims.findIndex(
          (x) => x.id === deleteClaim.value.id
        );
        if (index !== -1) {
          // claim status = d = delete
          response.value.claims[index] = {
            ...response.value.claims[index],
            approved_qty: null,
            approved_price: null,
            comment: comment.value,
            status: "d",
          };
          // encounter status = p = pending vetting
          response.value.status = "p";

          // clear entry
          claimInputClear();
        }
      } else {
        // input default
        comment.value = "Not applicable";
        deleteHolder.value = true;

        // reset error (if required)
        error.value = false;
      }
    };

    // load claims
    const handleClaimEdit = () => {
      // start action
      if (item.value && qty.value && amt.value) {
        if (
          (editHolder.value && comment.value) ||
          Number(amt.value) >= Number(editClaim.value.sent_price)
        ) {
          // format for saving
          const { fx } = capitalizeFirstLetter(item.value);

          if (editClaim.value) {
            // edit
            const index = response.value.claims.findIndex(
              (x) => x.id === editClaim.value.id
            );
            if (index !== -1) {
              // found record
              if (
                Number(amt.value) >
                Number(response.value.claims[index].sent_price)
              ) {
                // reload sent price & qty
                response.value.claims[index] = {
                  ...response.value.claims[index],
                  item: fx.value,
                  approved_qty: response.value.claims[index].sent_qty,
                  approved_price: response.value.claims[index].sent_price,
                  comment: null,
                };
              } else {
                // load vetted price & qty
                response.value.claims[index] = {
                  ...response.value.claims[index],
                  item: fx.value,
                  approved_qty: qty.value,
                  approved_price: amt.value,
                  comment: comment.value,
                };
              }
              // encounter status = p = pending vetting
              response.value.status = "p";
            }
          }

          // clear entry
          claimInputClear();

          // reset error (if required)
          error.value = false;
        } else {
          // input default
          if (editClaim.value.comment) {
            comment.value = editClaim.value.comment;
          } else {
            comment.value = "Over Prescription";
          }
          editHolder.value = true;

          // reset error (if required)
          error.value = false;
        }
      } else {
        error.value = true;
      }
    };

    // reject encounter
    const handleEncounterDelete = () => {
      if (deleteEncounter.value && comment.value) {
        // decline workflow
        response.value.claims.forEach((x) => {
          // spare authorization z and y
          if (x.authorization == "n") {
            x.approved_qty = null;
            x.approved_price = null;
            x.comment = comment.value;
          }
          x.status = "d";
        });

        // call handle next
        handleNext("reject");

        // clear entry
        claimInputClear();
      } else {
        // set error
        error.value = true;
      }
    };

    // watch qty & price
    const handlePricing = () => {
      amt.value = (Number(qty.value) * Number(standard.value)).toFixed(2);
    };

    // refresh field
    const handleRefresh = () => {
      // clear entry
      claimInputClear();
    };

    // handle back
    const handleBack = () => {
      // done
      context.emit("vetted", {
        status: "back",
      });
    };

    // handle approve
    const handleNext = async (val) => {
      // starter
      isLoading.value = true;

      // approve workflow
      if (val == "approve") {
        // start
        let newVal = {
          encounter: { id: response.value.id, status: "v" },
          claims: response.value.claims,
        };

        // post update
        const { res, err } = await postItem("claim/update/bulk", newVal);

        if (res.value) {
          // mark
          response.value.status = "v";
        } else {
          error.value = err.value;
        }
      }

      // reject workflow
      if (val == "reject") {
        // start
        let newVal = { status: "d" };

        // put update
        const { res, err } = await putItem(
          "encounter",
          response.value.id,
          newVal
        );

        if (res.value) {
          // mark
          response.value.status = "d";
        } else {
          error.value = err.value;
        }
      }

      // update
      if (val != "next") {
        // next is from onMounted!
        responseAll.value.forEach((x) => {
          if (x.id == response.value.id) {
            x = response.value; // update
          }
        });
        // clear regardless
        response.value = null;
      }

      // next
      responseAll.value.forEach((x, y) => {
        if (x.status == "p") {
          // good to go
          response.value = x;
          // number
          curr.value = y + 1;
        }
      });

      // route
      if (!response.value) {
        // done
        context.emit("vetted", {
          status: "success",
          value: responseAll.value,
        });
      }

      isLoading.value = false; // end
    };

    // output encounter results
    const encounter = computed(() => {
      if (!response.value) {
        return null;
      } else {
        response.value.claims.forEach((x) => {
          if (!x.approved_price) {
            x.approved_qty = x.sent_qty;
            x.approved_price = x.sent_price;
          }
        });

        return response.value;
      }
    });

    return {
      handlePricing,
      handleEdit,
      handleReset,
      handleDelete,
      handleReVet,
      handleEncounterForDelete,
      handleEncounterDelete,
      handleClaimDelete,
      handleClaimEdit,
      handleRefresh,
      handleNext,
      handleBack,
      totalSent,
      totalApproved,
      curr,
      total,
      deleteClaim,
      deleteHolder,
      editClaim,
      editHolder,
      deleteEncounter,
      isLoading,
      error,
      claimsCard,
      claimsDisplayCard,
      claimsSubmitCard,
      claimsList,
      item,
      qty,
      amt,
      standard,
      comment,
      encounter,
    };
  },
};
</script>
  
<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.custom-hr {
  width: 50%; /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc; /* Adjust the color and style as needed */
  margin: 20px 20px; /* Adjust margin as needed */
}

.counter {
  display: block;
  padding: 0.5em; /* Adjust padding to your liking */
  /* font-size: 1em;  Adjust font size */
}

.clickable {
  cursor: pointer;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  /* opacity: 1; Default opacity */
  /* transition: opacity 0.3s ease;  Smooth opacity change */
}

/* .pointable:hover {
    opacity: 0.8; Slightly reduced opacity on hover
  } */

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>