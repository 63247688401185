<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'claim' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >description</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <router-link :to="{ name: 'claim' }"
          ><i class="small material-icons" title="Go Back"
            >subdirectory_arrow_left</i
          ></router-link
        >
        <small> Add Claims</small>
      </h3>
    </div>

    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <!-- Error -->
      <p class="chip red white-text" v-if="error">{{ error }}</p>
      <!-- Main Displays  -->
      <template v-if="cluster">
        <div class="section">
          <div class="card">
            <span class="new badge left" data-badge-caption="Scheme">
              {{ cluster.scheme.name.toUpperCase() }}
            </span>
            <div class="card-content">
              <span class="card-title black-text"
                >{{ cluster.provider.name }}
                <span class="chip"
                  ><b
                    >{{ cluster.month.name }} {{ cluster.year.name }} Claims</b
                  ></span
                >

                <a
                  href="#"
                  class="secondary-content teal-text text-darken-1 dropdown-trigger right"
                  data-target="dropmenu"
                  ><i class="small material-icons" title="review"
                    >more_vert</i
                  ></a
                >

                <!-- DropMenu -->
                <ul id="dropmenu" class="dropdown-content">
                  <li @click.prevent="handleReview('view')">
                    <a href="#">View All Pages <b>({{ encounters.length? encounters.length : 0 }})</b></a>
                  </li>
                  <li class="divider" tabindex="-1"></li>
                  <li @click.prevent="handleReview('add')">
                    <a href="#">Add New</a>
                  </li>
                </ul>
              </span>
            </div>
          </div>

          <EnrolleeClaim
            :cluster="cluster"
            :reload="encounter"
            @next="handleNext"
            v-if="next"
          />

          <div v-else>
              <SummaryClaim :encounters="encounters" @reload="handleReload" />
            <p>
              <button
                class="waves-effect waves-light btn-large black left"
                :class="{
                  disabled: isSubmitting
                }"
                @click="handleAdd"
              >
                <i class="medium material-icons left">add</i>Add
              </button>

              <button
                class="waves-effect waves-light btn-large right"
                :class="{
                  disabled: isSubmitting || !encounters.length,
                }"
                @click="handleSubmit"
              >
                <i class="medium material-icons right"
                  >check_circle</i
                >{{ isSubmitting ? "Submitting ..." : "Final Submit" }}
              </button>
            </p>
          </div>
        </div>
      </template>

      <StartClaim @next="handleNext" v-if="!cluster" />
    </template>
  </div>
</template>
    
  
<script>
import { ref, onMounted, onUpdated, computed } from "vue";
import { useRouter } from "vue-router";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import StartClaim from "@/components/claim/StartClaimComponent.vue";
import EnrolleeClaim from "@/components/claim/EnrolleeClaimComponent.vue";
import SummaryClaim from "@/components/claim/SummaryClaimComponent.vue";
import M from "materialize-css";

export default {
  name: "claimAdd",
  components: { LoadingDiv, StartClaim, EnrolleeClaim, SummaryClaim },
  setup() {
    const isReady = ref(true);
    const isSubmitting = ref(false);
    const cluster = ref(null);
    const encounter = ref(null);
    const editId = ref(null);
    const next = ref(false);
    const error = ref(null);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Claims | EasyHMO";
    });

    // more
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // handle next
    const handleNext = async (val) => {
      if (!cluster.value) {
        // start
        isReady.value = false;

       // setup
       let newVal = {
        provider_id: val.provider.search,
        month_year: `${val.month.search}_${val.year.search}`,
        scheme_id: val.scheme.search
        };

        // post update
        const { res, err } = await postItem("cluster", newVal);

        if (res.value) {
          if (!res.value.data.encounters.length) {
            // load all from startClaim
            cluster.value = val;
            // add cluster id
            cluster.value.id = res.value.data.id;
            // add cluster id
            cluster.value.provider_id = val.provider.search;
            // encounter[] added from serve
            cluster.value.encounters = res.value.data.encounters;
            // open enrolleeClaim
            next.value = true;
          }else{
            error.value = "Claims month already active!";
          }
        } else {
          error.value = err.value;
        }

       //end
       isReady.value = true; 

      } else {
        if ("encounter" in val) {
          // start
          let newVal = true;
          // update old encounter if edit
          if (editId.value) {
            cluster.value.encounters.forEach((x, y) => {
            if (x.editId == val.encounter.editId) {
              cluster.value.encounters[y] = val.encounter;
              newVal = false;
            }
          });
          }
          // set new encounter
          if (newVal) {
            cluster.value.encounters.push(val.encounter);
          }
          // close encounter
          encounter.value = null;
          // close enrolleeClaim
          next.value = false;
          // clear edit (if true)
          editId.value = null;
        }
      }
    };

    // handle reload
    const handleReload = (val) => {
      encounter.value = val.encounter;
      editId.value = val.encounter.editId;
      next.value = true;
    };

    // handle review
    const handleReview = (val) => {
      if (val == "view") {
        next.value = false;
      } else if (val == "add") {
        next.value = true;
      }
    };

    // handle add
    const handleAdd = () => {
      // open new
      next.value = true;
      // close old encounter
      encounter.value = null;
    };

    // handle review
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;

       // start
       let newCluster;
       let newEncounter;
       let newEncounters = [];
       let newClaims = [];
       let keysToRemove = ["id", "scheme", "created_at", "updated_at", "editId", "claims", "status"];
      
       // encounters && claims
       encounters.value.forEach((x) => {
        // sort encounters
        newEncounter = {
        ...Object.fromEntries(
          Object.entries(x).filter(
            ([key]) => !keysToRemove.includes(key)
          )
        ),
      };
        newEncounter = ({
         ...newEncounter,
         encounter_cluster_id: cluster.value.id,
         provider_id: cluster.value.provider_id,
         status: "p", // ready for vetting
        })

        // sort claims
        x.claims.forEach((z) => {
           newClaims.push({
            encounter_id: null,
            item: z.claim,
            sent_qty: z.qty,
            sent_price: z.amt,
            standard_price: z.standard,
           })
        })

        // put everything together
        newEncounters.push({ encounter: newEncounter, claims: newClaims });

        // clean up and go again
        newEncounter = null;
        newClaims = [];

       });

       // update cluster status
       newCluster = {
        id: cluster.value.id,
        status: "p", // make ready for vetting
       }

       // setup
       let newVal = {
          cluster: newCluster,
          encounters: newEncounters,
        };

        // post update
        const { res, err } = await postItem("encounter/claims/cluster/bulk", newVal);

        if (res.value) {
          // mark
          router.push({ name: "claim" }); // final back
        } else {
          error.value = err.value;
        }

        // end
        isSubmitting.value = false; // in case of incasecity!
    };

    // output claims results
    const encounters = computed(() => {
      if (!("encounters" in cluster.value)) {
        return [];
      } else {
        return cluster.value.encounters;
      }
    });

    return {
      showAgeSex,
      handleReview,
      handleNext,
      handleReload,
      handleAdd,
      handleSubmit,
      error,
      isReady,
      isSubmitting,
      next,
      cluster,
      encounter,
      encounters,
    };
  },
};
</script>
  
<style>
</style>