<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'auth' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >insert_comment</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <router-link :to="{ name: 'auth' }"
          ><i class="small material-icons" title="Go Back"
            >subdirectory_arrow_left</i
          ></router-link
        >
        <small> View Auth</small>
      </h3>
    </div>

    <div class="section center">
      <span
        v-for="tab in tabs"
        :key="tab.id"
        :class="{
          'tab-custom-active': tab.id == currTab.id,
          'tab-custom': tab.id != currTab.id,
        }"
        @click="handleActive(tab)"
      >
        {{ tab.title }}
      </span>
    </div>

    <div class="row">
      <div class="col s12">
        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section">
            <!-- Auth Code -->
            <div class="card" v-if="encounter">
              <span class="new badge left" data-badge-caption="Scheme">{{
                encounter.scheme.name.toUpperCase()
              }}</span>

              <!-- Enrollee -->
              <div class="card-content">
                <div class="row">
                  <div class="col s8">
                    <p>{{ encounter.client_name }}</p>
                    <h5>
                      {{ encounter.name }}
                      <sup
                        ><small
                          ><span class="black-text">{{
                            showAgeSex(encounter.dob, encounter.sex)
                          }}</span></small
                        ></sup
                      >
                    </h5>
                    <h6>({{ encounter.enrollee_number }})</h6>
                  </div>
                  <div class="col s4">
                    <img
                      :src="encounter.profile_img"
                      width="96"
                      class="circle responsive-img right"
                      v-if="encounter.profile_img"
                    />
                    <img
                      :src="`https://ui-avatars.com/api/?name=${encounter.name}&size=128`"
                      width="96"
                      class="circle responsive-img right"
                      v-else
                    />
                  </div>
                </div>
              </div>

              <!-- Review (Auth) -->
              <template v-if="currTab.title == 'Review'">
              <div
                class="card"
                :class="{
                  'blue-grey lighten-5': !error,
                  'red lighten-5': error,
                }"
              >
                <!-- Provider -->
                <div class="card-panel blue-grey lighten-5">
                  <div class="row">
                    <div class="col s12">
                      <span class="card-title">{{
                        encounter.provider.name
                      }}</span>
                      <h6 class="teal-text">
                        {{
                          new Date(encounter.encounter_date).toLocaleString(
                            undefined,
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )
                        }}
                      </h6>
                    </div>
                  </div>
                </div>

                <!-- Diagnosis -->
                <div class="card-panel yellow lighten-1">
                  <span class="collections black-text">
                    <h6>
                      <i class="tiny material-icons">bookmark_border</i>
                      Diagnosis: {{ encounter.diagnosis }}
                    </h6>
                  </span>
                </div>

                <!-- Approvals Display-->
                <div class="card" v-if="auths.length">
                  <ol class="collection teal lighten-4">
                    <span v-for="auth in auths" :key="auth.id">
                      <!-- approved -->
                      <li
                        class="collection-item teal lighten-4"
                        v-if="auth.authorization == 'y'"
                      >
                        <b>{{ auth.item }}</b> x {{ auth.sent_qty }} (Charged:
                        ₦{{
                          auth.sent_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}) -> (Approved: ₦{{
                          auth.approved_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}) ->
                        <span
                          class="blue-text tooltipped pointable"
                          data-position="top"
                          :data-tooltip="auth.authorization_hmo_comment"
                          v-if="auth.authorization_hmo_comment"
                          >({{ auth.authorization_code }})</span
                        >
                        <span class="black-text" v-else
                          >({{ auth.authorization_code }})</span
                        >

                        <span
                          class="secondary-content green-text pointable"
                          title="Approved"
                          ><i class="material-icons">check_circle</i></span
                        >
                      </li>

                      <!-- declined -->
                      <li
                        class="collection-item teal lighten-4"
                        v-if="auth.authorization == 'z' && auth.status == 'd'"
                      >
                        <s>
                          <span
                            class="tooltipped pointable"
                            data-position="top"
                            :data-tooltip="auth.authorization_hmo_comment"
                            v-if="auth.authorization_hmo_comment"
                          >
                            <b>{{ auth.item }}</b> x
                            {{ auth.sent_qty }} (Charged: ₦{{
                              auth.sent_price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }})
                          </span>

                          <span v-else>
                            <b>{{ auth.item }}</b> x
                            {{ auth.sent_qty }} (Charged: ₦{{
                              auth.sent_price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }})
                          </span>
                        </s>

                        <span
                          class="secondary-content red-text pointable"
                          title="Declined"
                          ><i class="material-icons">cancel</i></span
                        >
                      </li>
                      <div class="divider"></div>
                    </span>
                  </ol>
                </div>

                <!-- Requests -->
                <span v-for="(auth, index) in auths" :key="auth.id">
                  <span v-if="auth.authorization == 'z' && auth.status == 'a'">
                    <div class="card-panel" v-if="auth.next == 'y'">
                      <form
                        @submit.prevent="
                          handleAction({
                            type: action,
                            auth: auth,
                            point: index,
                          })
                        "
                      >
                        <div class="row">
                          <!-- Approval -->
                          <template v-if="action == 'approve'">
                            <div class="input-field col s6">
                              <input
                                v-model="item"
                                id="item"
                                type="text"
                                readonly
                              />
                              <label class="active" for="item">Item</label>
                            </div>
                            <div class="input-field yellow lighten-4 col s1">
                              <input
                                v-model="qty"
                                @input="handlePricing"
                                id="qty"
                                type="number"
                              />
                            </div>
                            <div class="input-field col s2">
                              <input
                                v-model="amt"
                                id="amt"
                                type="number"
                                readonly
                              />
                              <label class="active" for="amt">Amount</label>
                            </div>
                            <div class="input-field col s3">
                              <input
                                v-model="comment"
                                id="comment"
                                type="text"
                                :class="{
                                  validate: qty != auth.sent_qty && qty,
                                }"
                                :required="qty != auth.sent_qty && qty"
                                :readonly="qty == auth.sent_qty || !qty"
                              />
                              <label
                                :class="{
                                  'active teal-text':
                                    qty != auth.sent_qty && qty,
                                }"
                                for="comment"
                                >Comment</label
                              >
                            </div>
                          </template>

                          <!-- Decline -->
                          <template v-else>
                            <div class="input-field col s12">
                              <input
                                v-model="comment"
                                id="comment"
                                type="text"
                                class="validate"
                                required
                              />
                              <label class="active" for="comment"
                                >Comment</label
                              >
                            </div>
                          </template>

                          <!-- Approve -->
                          <div
                            class="input-field col s12"
                            v-if="action == 'approve'"
                          >
                            <a
                              class="waves-effect waves-light btn red lighten-2"
                              :class="{ disabled: isSubmitting }"
                              @click.prevent="
                                handleAction({
                                  type: 'decline_confirm',
                                })
                              "
                            >
                              <i class="medium material-icons left">clear</i
                              >Decline
                            </a>

                            <button
                              class="waves-effect waves-light btn teal lighten-1 right"
                              :class="{ disabled: isSubmitting }"
                            >
                              <i class="medium material-icons left"
                                >check_circle</i
                              >{{ isSubmitting ? "Approving ..." : "Approve" }}
                            </button>
                          </div>

                          <!-- Decline -->
                          <div class="input-field col s12" v-else>
                            <a
                              class="waves-effect waves-light btn black"
                              :class="{ disabled: isSubmitting }"
                              @click.prevent="
                                handleAction({
                                  type: 'back',
                                })
                              "
                            >
                              <i class="medium material-icons left"
                                >chevron_left</i
                              >Back
                            </a>
                            <button
                              class="waves-effect waves-light btn red lighten-2 right"
                              :class="{ disabled: isSubmitting }"
                            >
                              <i class="medium material-icons left"
                                >highlight_off</i
                              >{{ isSubmitting ? "Declining ..." : "Confirm" }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div class="card-panel blue-grey lighten-5" v-else>
                      <div class="row">
                        <div class="input-field col s6">
                          <input
                            :value="auth.item"
                            id="item"
                            type="text"
                            disabled
                          />
                          <label class="active" for="item">Item</label>
                        </div>
                        <div class="input-field grey lighten-4 col s1">
                          <input
                            :value="auth.sent_qty"
                            @input="handlePricing"
                            id="qty"
                            type="number"
                            class="validate"
                            disabled
                          />
                        </div>
                        <div class="input-field col s2">
                          <input
                            :value="auth.sent_price"
                            id="amt"
                            type="number"
                            disabled
                          />
                          <label class="active" for="amt">Amount</label>
                        </div>
                        <div class="input-field col s3">
                          <input
                            :value="auth.authorization_hmo_comment"
                            id="comment"
                            type="text"
                            disabled
                          />
                          <label class="active" for="comment">Comment</label>
                        </div>

                        <div class="input-field col s12">
                          <a
                            class="waves-effect waves-light btn red lighten-2 disabled"
                          >
                            <i class="medium material-icons left">clear</i
                            >Decline
                          </a>

                          <button
                            class="waves-effect waves-light btn teal lighten-1 right disabled"
                          >
                            <i class="medium material-icons left"
                              >check_circle</i
                            >Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                <!-- End -->

                <br />
              </div>
              </template>
              
              <!-- Benefits -->
              <BenefitsDiv id="1" v-if="currTab.title == 'Benefits'" />
              
              <!-- History -->
              <HistoryAuth :enrolleeNumber="encounter.enrollee_number" v-if="currTab.title == 'History'" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import BenefitsDiv from "@/components/plan/ShowBenefitComponent.vue";
import HistoryAuth from "@/components/auth/HistoryAuthComponent.vue";
import M from "materialize-css";

export default {
  name: "authView",
  components: { LoadingDiv, BenefitsDiv, HistoryAuth },
  props: ["enrolleeId", "claimId", "encounterId"],
  setup(props) {
    const responseEncounter = ref(null);
    const responseAuths = ref([]);
    const isReady = ref(false);
    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const tabs = ref([]);
    const currTab = ref(null);
    const error = ref(null);
    const item = ref(null);
    const qty = ref(null);
    const amt = ref(null);
    const standard = ref(null);
    const comment = ref(null);
    const action = ref("approve");

    // router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Approval Authorization | EasyHMO";
    });

    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Review" });
      tabs.value.push({ id: 2, title: "Benefits" });
      tabs.value.push({ id: 3, title: "History" });
      // set default
      currTab.value = tabs.value[0];
    });

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // get function
    const getSingleItemFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          // encounter
          responseEncounter.value = res.value.data;
          // auths
          responseAuths.value = res.value.data.claims.filter(
            (x) => x.authorization != "n"
          );
          // sort auths
          responseAuths.value.sort((a, b) => {
            return a.authorization.localeCompare(b.authorization);
          });
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // starter
    onMounted(() => {
      // get items from function
      getSingleItemFromApi("encounter", props.encounterId);
    });

    // toolTip
    const toolTip = () => {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {});
    };

    // load after data loads and updates
    watchEffect(() => {
      if (responseAuths.value) {
        setTimeout(() => {
          toolTip();
        }, 2000);
      }
    });

    // populate as required
    watchEffect(() => {
      if (responseAuths.value) {
        // Send to Auth View // load single for vetting
        responseAuths.value.forEach((x) => {
          if (
            x.id == props.claimId &&
            x.authorization == "z" &&
            x.status == "a"
          ) {
            // update values
            item.value = x.item;
            qty.value = x.sent_qty;
            amt.value = x.sent_price;
            standard.value = x.standard_price;
            comment.value = x.authorization_hmo_comment;
            // set next
            x.next = "y"; // yes
          } else {
            x.next = "n"; // no
          }
        });
      }
      // assurance
      if (!item.value) {
        let newAuth = null;
        responseAuths.value.forEach((x) => {
          if (
            x.id != props.claimId &&
            x.authorization == "z" &&
            x.status == "a"
          ) {
            newAuth = x.id;
          }
        });

        if (newAuth) {
          router.push({
            name: "authView",
            params: {
              enrolleeId: props.enrolleeId,
              claimId: newAuth,
              encounterId: props.encounterId,
            },
          });
        }
      }
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    // pricing
    const handlePricing = () => {
      amt.value = (Number(qty.value) * Number(standard.value)).toFixed(2);
    };

    // clear variable
    const handleClear = () => {
      item.value = null;
      qty.value = null;
      amt.value = null;
      standard.value = null;
      comment.value = null;
      action.value = "approve";
      isSubmitting.value = false; // end
    };

    // action
    const handleAction = async (val) => {
      // clear old errors
      error.value = false;

      if (qty.value) {
        // start
        let newAuth = null;
        let passPort = false;
        responseAuths.value.forEach((x) => {
          if (
            x.id != props.claimId &&
            x.authorization == "z" &&
            x.status == "a"
          ) {
            newAuth = x.id;
          }
        });

        // operations
        if (val.type == "approve") {
          // update
          isSubmitting.value = true; // start
          let newAuthCode = `HMO-AUTH-${props.claimId}`;
          let newVal = {
            approved_qty: qty.value,
            approved_price: amt.value,
            authorization_hmo_comment: comment.value,
            authorization: "y",
            authorization_code: newAuthCode,
            status: "a",
          };

          // put item
          const { res, err } = await putItem("claim", props.claimId, newVal);

          if (res.value) {
            // approve
            val.auth.approved_qty = qty.value;
            val.auth.approved_price = amt.value;
            val.auth.authorization_hmo_comment = comment.value; // comment
            val.auth.authorization_code = newAuthCode; // newly generated code
            val.auth.authorization = "y";
            val.auth.next = "n";

            // clear variable
            handleClear();

            // load
            responseAuths.value[val.point] = val.auth;

            // free to go
            passPort = true;
          } else {
            error.value = err.value;
            isSubmitting.value = false; // end
          }
        } else if (val.type == "decline_confirm") {
          // confirm decline
          action.value = "decline";
          error.value = false;
          newAuth = null;
        } else if (val.type == "decline") {
          // update
          isSubmitting.value = true; // start
          let newVal = {
            authorization_hmo_comment: comment.value,
            authorization: "z", // as is
            status: "d",
          };

          // post item
          const { res, err } = await putItem("claim", props.claimId, newVal);

          if (res.value) {
            // decline (comments checked by materializedCSS)
            val.auth.authorization = "z"; // as is
            val.auth.status = "d"; // delete
            val.auth.authorization_hmo_comment = comment.value; // comment
            val.auth.next = "n";

            // clear variable
            handleClear();

            // load
            responseAuths.value[val.point] = val.auth;

            // free to go
            passPort = true;
          } else {
            error.value = err.value;
            isSubmitting.value = false; // end
          }
        } else if (val.type == "back") {
          // clear everything
          newAuth = null;
          error.value = false;
          action.value = "approve";
        }

        //end
        if (passPort) {
          if (newAuth) {
            router.push({
              name: "authView",
              params: {
                enrolleeId: props.enrolleeId,
                claimId: newAuth,
                encounterId: props.encounterId,
              },
            });
          } else {
            // final end
            router.push({ name: "auth" });
          }
        }
      } else {
        error.value = true;
        isSubmitting.value = false; // end
      }
    };

    // output encounter
    const encounter = computed(() => {
      if (!responseEncounter.value) {
        return null;
      } else {
        return responseEncounter.value;
      }
    });

    // output auths
    const auths = computed(() => {
      if (!responseAuths.value.length) {
        return [];
      } else {
        return responseAuths.value;
      }
    });

    return {
      showAgeSex,
      handleActive,
      handlePricing,
      handleAction,
      isReady,
      isLoading,
      isSubmitting,
      tabs,
      currTab,
      error,
      item,
      qty,
      amt,
      action,
      comment,
      encounter,
      auths,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

.custom-hr {
  width: 50%; /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc; /* Adjust the color and style as needed */
  margin: 20px 20px; /* Adjust margin as needed */
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}

.clickable {
  cursor: pointer;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}
</style>
