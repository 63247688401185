<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'claim' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >description</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <small>Claims</small>
        <span class="chip">
          <router-link :to="{ name: 'claimAdd' }"
            ><i
              class="small material-icons teal-text text-darken-1"
              title="Add Claims"
              >add_circle</i
            ></router-link
          ></span
        >
      </h3>
    </div>

    <div class="row">
      <div class="col s12">
        <!-- FilterAuth -->
        <FilterClaim @search="handleSearch" />

        <LoadingDiv v-if="!isReady" />

        <div v-else>
          <div class="section black-text center">
            <br />
            <h5 v-if="searchTitle" v-html="searchTitle"></h5>
            <h5 v-else>
              Displaying <span class="blue-text">Everything!</span>
              <i class="material-icons">check</i>
            </h5>
          </div>

          <div class="section">
            <div class="card-panel">
              <div class="row">
                <div class="col s1">#</div>
                <div class="col s3">Provider <small>(claims)</small></div>
                <div class="col s2">Sent</div>
                <div class="col s2">Approved</div>
                <div class="col s2">Claims <small>(scheme)</small></div>
                <div class="col s1">Payment</div>
                <div class="col s1"><br /></div>
              </div>
            </div>

            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>

            <div
              class="card-panel"
              :class="{
                'blue-grey lighten-5': (index + 1) % 2 === 1,
              }"
              v-for="(cluster, index) in clusters"
              :key="cluster.id"
            >
              <div class="row">
                <div class="col s1">
                  <p>{{ numberBadge(index) }}</p>
                </div>
                <div class="col s3">
                  <h6>
                    <span
                      v-if="
                        cluster.encounters.filter(
                          (x) => x.compliance_flag == 'y'
                        ).length
                      "
                      ><i
                        class="tiny material-icons red-text pointer"
                        title="compliance issue"
                        >flag</i
                      ></span
                    >
                    {{ cluster.provider.name }}
                    <br />
                    <span v-if="cluster.status !== 'q'"
                      ><small
                        >({{ cluster.encounters.length }} Claims)</small
                      ></span
                    >
                  </h6>
                </div>
                <div class="col s2">
                  <p>
                    <span class="red-text" v-if="cluster.status === 'q'"
                      >₦0.00</span
                    >
                    <span class="red-text" v-else
                      >₦{{ grossTotalSent(cluster.encounters) }}</span
                    >
                  </p>
                </div>
                <div class="col s2">
                  <p>
                    <span class="chip" v-if="cluster.status === 'p'"
                      >Unvetted</span
                    >
                    <span class="chip yellow" v-if="cluster.status === 'q'"
                      >Unsubmitted</span
                    >
                    <span
                      class="green-text"
                      v-if="cluster.status === 'r' || cluster.status === 'v'"
                      >₦{{ grossTotalSent(cluster.encounters) }}</span
                    >
                  </p>
                </div>
                <div class="col s2">
                  <p>
                    {{ toMonthYear(cluster.month_year) }}
                    <small
                      ><b>({{ cluster.scheme.name }})</b></small
                    >
                  </p>
                </div>
                <div class="col s1">
                  <p>
                    <span
                      class="chip green"
                      v-if="checkPaid(cluster.payment, cluster.repayment)"
                      >Paid</span
                    >
                    <span v-else>{{
                      paymentStatus(
                        cluster.status,
                        cluster.payment,
                        cluster.repayment
                      )
                    }}</span>
                  </p>
                </div>
                <div class="col s1">
                  <span v-if="cluster.status === 'q'">
                    <span class="btn-floating black pointable right"
                      ><i class="material-icons" title="locked"
                        >lock_outline</i
                      ></span
                    >
                  </span>

                  <span v-if="cluster.status === 'p'">
                    <a class="btn-floating red right"
                      ><i
                        class="material-icons"
                        title="review"
                        @click="handleEdit(cluster)"
                        >chevron_right</i
                      ></a
                    >
                  </span>

                  <span v-if="cluster.status === 'r' || cluster.status === 'v'">
                    <a
                      class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                      ><i
                        class="material-icons"
                        @click="handleEdit(cluster)"
                        title="details"
                        >chevron_right</i
                      ></a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="section center" v-if="!clusters.length">
            <h6>No Claims <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import FilterClaim from "@/components/claim/FilterClaimComponent.vue";
import { cacheGet, cachePush } from "@/composables/cache/cache.js";

export default {
  name: "claimHome",
  components: { LoadingDiv, PaginationDiv, FilterClaim },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const searchTitle = ref(null);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "All Claims Cluster | easyHMO";
    });

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      let total = 0;
      encounters.forEach((z) => {
        z.claims.forEach((x) => {
          if (x.sent_price) {
            total = Number(total) + Number(x.sent_price);
          }
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      let parts = val.split("_");
      let month = parseInt(parts[0]);
      let year = parseInt(parts[1]);
      let date = new Date(year, month - 1); // months are zero-indexed in JavaScript
      let monthName = date.toLocaleString("default", { month: "short" });
      return monthName + " " + year;
    };

    // payment status
    const checkPaid = (payment, repayment) => {
      let val = false;

      if (payment) {
        if (payment.stage == "c") {
          val = true;
        }
      }

      if (repayment) {
        if (repayment.stage == "c") {
          val = true;
        }
      }

      return val;
    };

    // payment status
    const paymentStatus = (status, payment, repayment) => {
      let val = "N/A";
      if (payment) {
        if (payment.stage == "p") {
          val = `(Batched #${payment.id})`;
        } else if (payment.stage == "q") {
          val = `(Queued #${payment.id})`;
        } else if (payment.stage == "c") {
          val = `(Paid #${payment.id})`;
        }
      } else {
        if (status == "v") {
          val = "Pending Batching";
        }
      }

      if (repayment) {
        if (repayment.stage == "p") {
          val = `(Batched #${repayment.id})`;
        } else if (repayment.stage == "q") {
          val = `(Queued #${repayment.id})`;
        } else if (repayment.stage == "c") {
          val = `(Paid #${repayment.id})`;
        }
      } else {
        if (status == "r") {
          val = "Pending Batching";
        }
      }

      return val;
    };

    // get function
    const getItemsFromApi = (table) => {
      // get cache
      response.value = cacheGet(table);

      if (response.value) {
        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      }

      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          // push cache
          cachePush(table, response.value);
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter WAIT
    // onMounted(() => {
    //   // set current Url
    //   currUrl.value = "cluster";
    //   // set current Hook
    //   currHook.value = "?";
    //   // get items from function
    //   getItemsFromApi(currUrl.value);
    // });

    // handle search (emitted from component)
    const handleSearch = (val) => {
      // start loading
      isLoading.value = true;
      // set current Url
      currUrl.value = val.currUrl;
      // set current Hook
      currHook.value = val.currHook;
      // set search title
      searchTitle.value = val.searchTitle;
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // handle edit
    const handleEdit = (cluster) => {
      router.push({ name: "claimView", params: { clusterId: cluster.id } });
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const clusters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data; // get data portion
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      numberBadge,
      grossTotalSent,
      toMonthYear,
      checkPaid,
      paymentStatus,
      handlePage,
      handleSearch,
      handleEdit,
      isReady,
      isLoading,
      error,
      searchTitle,
      clusters,
      allPages,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}

.clickable {
  cursor: pointer;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.clickable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}

.pointable {
  cursor: default;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}
</style>