<template>
  <LoadingDiv v-if="isLoading" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <!-- <h1 class="teal-text">
              <i class="medium material-icons">filter_4</i> Base Tariffs
            </h1> -->

            <div class="section">
              <h5 class="header">Added Tariffs</h5>
            </div>

            <!-- Completed -->
            <div class="section">
              <div
                class="card-panel"
                v-for="(plan, id) in planComplete"
                :key="plan.id"
              >
                <div class="row">
                  <div class="col s1">
                    <span class="chip grey darken-2 white-text">{{
                      id + 1
                    }}</span>
                  </div>
                  <div class="col s9">
                    <h5>
                      {{ plan.plan }}
                    </h5>
                    <h6>
                      Scheme:
                      <span class="teal-text">
                        {{
                          plan.scheme == "p"
                            ? "Private Scheme"
                            : "Social Scheme"
                        }}
                      </span>
                    </h6>
                    <p>
                      (<b>Tariffs</b>) {{ plan.tariff }} line items defined
                      <i
                        class="material-icons clickable brown-text"
                        title="view all"
                        >visibility</i
                      >
                    </p>
                  </div>
                  <div class="col s2">
                    <i
                      class="material-icons teal-text secondary-content"
                      title="uploaded"
                      >check_circle</i
                    >
                  </div>
                </div>
              </div>

              <!-- Empty -->
              <div v-if="!planComplete.length">
                <span class="chip">No Tariff <em>added</em></span>
              </div>
            </div>

            <!-- Uncompleted -->
            <template v-if="planPending.length">
              <div class="section">
                <h5 class="header"><em>Pending</em> Tariffs</h5>
              </div>

              <div class="section">
                <div
                  class="card-panel"
                  :class="{
                    'grey lighten-5': id > 0,
                    'red lighten-5': id == 0 && error,
                  }"
                  v-for="(plan, id) in planPending"
                  :key="plan.id"
                >
                  <template v-if="id == 0">
                    <form @submit.prevent="handleUpload(plan)">
                      <div class="row">
                        <div class="col s1">
                          <span class="chip red darken-2 white-text">{{
                            planComplete.length + id + 1
                          }}</span>
                        </div>
                        <div class="col s11">
                          <h5 class="black-text">
                            {{ plan.plan }}
                            <span class="teal-text"
                              ><small>{{
                                plan.scheme == "p"
                                  ? "Private Scheme"
                                  : "Social Scheme"
                              }}</small></span
                            >
                          </h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col s1"><br /></div>
                        <div class="col s9">
                          <div class="file-field input-field">
                            <div class="btn black">
                              <span>Tariff</span>
                              <input type="file" @change="handleFile" />
                            </div>
                            <div class="file-path-wrapper">
                              <input
                                class="file-path"
                                v-model="inputFile"
                                type="text"
                              />
                              <p class="red-text" v-if="fileError">
                                {{ fileError }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col 2">
                          <button
                            class="btn-floating btn-medium waves-effect waves-light black right"
                          >
                            <i class="material-icons" title="Upload tariff"
                              >upload</i
                            >
                          </button>
                        </div>
                      </div>
                    </form>
                  </template>

                  <template v-else>
                    <div class="row">
                      <div class="col s1">
                        <span class="chip red darken-2 white-text">{{
                          planComplete.length + id + 1
                        }}</span>
                      </div>
                      <div class="col s11">
                        <h5 class="black-text">
                          {{ plan.plan }}
                          <span class="teal-text"
                            ><small>{{
                              plan.scheme == "p"
                                ? "Private Scheme"
                                : "Social Scheme"
                            }}</small></span
                          >
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s1"><br /></div>
                      <div class="col s9">
                        <div class="file-field input-field">
                          <div class="btn black disabled">
                            <span>Tariff</span>
                            <input type="file" />
                          </div>
                          <div class="file-path-wrapper">
                            <input class="file-path" type="text" />
                          </div>
                        </div>
                      </div>
                      <div class="col 2">
                        <button
                          class="btn-floating btn-medium waves-effect waves-light black right disabled"
                        >
                          <i class="material-icons" title="Upload tariff"
                            >upload</i
                          >
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <!-- Submit -->
            <div class="section center">
              <p>
                <br /><br />
                <button
                  class="waves-effect waves-light btn-large"
                  :class="{ disabled: planPending.length }"
                  @click="handleNext"
                >
                  <i class="material-icons right">call_made</i>Submit
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <footer class="page-footer grey lighten-3">
        <div class="footer-copyright">
          <div class="container black-text">© 2024 easyHMO.com.ng</div>
        </div>
      </footer> -->

    </div>
  </template>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "TariffSetupComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const policy = ref([]);
    const planId = ref(null);
    const success = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // add variable
    onMounted(() => {
      policy.value.push({
        id: 1,
        plan: "LifeBlue",
        scheme: "p",
        tariff: 0,
        isComplete: false,
      });

      policy.value.push({
        id: 2,
        plan: "LifeGreen",
        scheme: "p",
        tariff: 0,
        isComplete: true,
      });

      policy.value.push({
        id: 3,
        plan: "Orhanges",
        scheme: "s",
        tariff: 0,
        isComplete: true,
      });

      policy.value.push({
        id: 4,
        plan: "LPlaschemma",
        scheme: "s",
        tariff: 0,
        isComplete: false,
      });

      policy.value.push({
        id: 5,
        plan: "LifePurplee",
        scheme: "p",
        tariff: 0,
        isComplete: true,
      });

      policy.value.push({
        id: 6,
        plan: "LifeBalnce",
        scheme: "p",
        tariff: 0,
        isComplete: false,
      });
    });

    // toMoney
    const toMoney = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // plan id
      planId.value = null;
      // next
      next.value = false;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
        error.value = false;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
        error.value = false;
      }
    };

    const handleUpload = (val) => {
      if (file.value) {
        // Start Uploading
        isSubmitting.value = true;
        isLoading.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "tariffLoader";

        // Start
        let schedule = {
          plan_id: val.id,
          type: "s", // c=customized, s=standard
        };

        // Round up
        const cloudInput = {schedule: schedule, provider: null, newProvider: null};

        // upload
        uploadFile(file.value, fx, cloudInput);

        // load planId
        planId.value = val.id;
      } else {
        // error
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // update plan
        policy.value.forEach((x) => {
          if (x.id == planId.value) {
            x.tariff = data.value.succ.inserted_rows;
            x.isComplete = true;
          }
        });

        // reset
        handleReset();
      }
      if (uploadError.value) {
        // restart
        handleReset();
      }
    });

    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 4,
        isComplete: true,
      });
    };

    // output results (plans)
    const planPending = computed(() => {
      if (!policy.value.length) {
        return [];
      } else {
        let newPolicy = policy.value.filter((x) => x.isComplete == false);
        return newPolicy;
      }
    });

    // output results (plans)
    const planComplete = computed(() => {
      if (!policy.value.length) {
        return [];
      } else {
        let newPolicy = policy.value.filter((x) => x.isComplete == true);
        return newPolicy;
      }
    });

    return {
      isLoading,
      isSubmitting,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      error,
      handleNext,
      handleUpload,
      handleFile,
      toMoney,
      success,
      next,
      planPending,
      planComplete,
    };
  },
};
</script>
    
<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.hover-text {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
</style>