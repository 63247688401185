<template>
  <div class="">
    <br />
    <br />
    <br />
    <br />
    <div class="container valign-wrapper">
      <div class="card-panel">
        <div class="col s12">
          <!-- Error -->
          <span class="chip yellow lighten-4" v-if="error">{{ error }}</span>

          <h2>Login to <br />your account</h2>
          <!-- </div>
        <div class="col s2"><br /></div>
        <div class="col s4"> -->
          <span class="right">
            <form @submit.prevent="handleSignUp">
              <input
                type="text"
                title="username"
                placeholder="Username"
                v-model="username"
              />
              <input
                type="text"
                title="role"
                placeholder="Role"
                v-model="role"
              />
              <input
                type="password"
                title="password"
                placeholder="Password"
                v-model="password"
              />
              <br /><br />
              <button type="submit" class="btn" v-if="!isSubmitting">
                Login
              </button>
              <LoadingSmallDiv v-else />
            </form>
          </span>
        </div>
      </div>
    </div>
  </div>
  <p><br /><br /><br /><br /><br /></p>
</template>
  
  <script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";

export default {
  name: "LoginHome",
  components: { LoadingSmallDiv },
  setup() {
    const isSubmitting = ref(false);
    const profile = ref([]);
    const username = ref(null);
    const role = ref(null);
    const schemes = ref([1]);
    const password = ref(null);
    const error = ref(null);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    onMounted(() => {
      // page name
      document.title = "Admin Login | easyHMO";
    });

    onMounted(() => {
      // clean up
      store.commit("loadUser", null);
      store.commit("loadRole", null);
      store.commit("loadSchemes", null);
      store.commit("loadAuthLevel", null);
      store.commit("loadModules", null);
      store.commit("loadCache", []);
    });

    const handleSignUp = () => {
      // start
      isSubmitting.value = true;
      error.value = null;

      // previledge
      if (role.value == "Master") {
        profile.value = [
          "enrollee",
          "auth",
          "claim",
          "client",
          "provider",
          "hospital",
          "private",
          "public",
          "retail",
          "tpa",
          "advisory",
          "report",
          "profit",
          "finance",
          "compliance",
          "settings",
          "adder",
          "bypass",
        ];
        schemes.value = [1, 2, 3, 4, 5, 6];
      } else if (role.value == "Quality Assurance") {
        profile.value = ["enrollee", "auth", "claim", "provider"];
      } else if (role.value == "Business Development") {
        profile.value = ["client", "advisory", "enrollee", "hospital"];
      } else if (role.value == "Underwriting") {
        profile.value = ["enrollee", "private", "retail", "tpa", "hospital"];
      } else if (role.value == "Social Scheme") {
        profile.value = ["enrollee", "auth", "claim", "public", "hospital"];
        schemes.value = [2, 3, 4, 5, 6];
      } else if (role.value == "Social Agent") {
        profile.value = ["enrollee", "auth", "claim", "hospital"];
        schemes.value = [2];
      } else if (role.value == "Compliance") {
        profile.value = ["advisory", "compliance", "report", "hospital"];
        schemes.value = [1, 2, 3, 4, 5, 6];
      } else if (role.value == "Finance") {
        profile.value = ["finance", "report", "hospital"];
      }

      //complete reg
      if (username.value && role.value && password.value && profile.value) {
        store.commit("loadUser", username.value);
        store.commit("loadRole", role.value);
        store.commit("loadSchemes", schemes.value);
        store.commit("loadAuthLevel", 5);
        store.commit("loadModules", profile.value);

        // refer back home
        router.push({
          name: "loading",
        });
      } else {
        error.value = "Sorry, wrong credentials";
      }

      isSubmitting.value = false;
    };

    return {
      handleSignUp,
      isSubmitting,
      username,
      role,
      password,
      error,
    };
  },
};
</script>
  
  <style>
.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 25px; /* Add padding to the left */
  padding-right: 25px; /* Add padding to the right */
}
</style>