<template>
  <div class="container white lighten-5 z-depth-4">
      <br />  
    <div class="row">
      <div class="col s12" v-for="tab in tabs" :key="tab.id">
        <router-link :to="{ name: tab.to }" class="grey-text text-darken-3">
          <div :class="`card-dashboard ${tab.card}`">
            <i
              class="medium material-icons blue-grey-text text-darken-2 left"
              >{{ tab.icon }}</i
            >
            <p
              class="chip yellow lighten-2 green-text text-darken-2 right"
              v-if="tab.notification"
            >
              {{ tab.notification }}
            </p>
            <h4>{{ tab.title }}</h4>
          </div>
        </router-link>
      </div>
    </div>
  <br />
    <!-- spacer -->
    <div v-if="tabs.length < 4">
      <p><br /><br /><br /></p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "HomeView",
  setup() {
    const tabs = ref([]);

    // store
    const store = useStore();

    // title
    onMounted(() => {
      // page name
      document.title = "EasyHMO Admin";
    });

    // display
    onMounted(() => {
      // load allowed
      if (store.state.modules) {
        tabs.value = tabs.value.filter((x) =>
          store.state.modules.includes(x.to)
        );
      } else {
        tabs.value = [];
      }
    });

    // previledge
    // profile.value = ["enrollee","auth","claim","provider"];
    // profile.value = ["enrollee","private","retail","tpa"];
    // profile.value = ["advisory","compliance","report"];
    // profile.value = ["finance","report"];
    // profile.value = ["enrollee","auth","claim"];
    // profile.value = ["enrollee","auth","claim","public"];
    // profile.value = ["client","advisory","enrollee"];

    // all
    tabs.value.push({
      id: tabs.value.length + 1,
      to: "enrollee",
      card: "card-1",
      icon: "people",
      title: "Enrollees",
      description: "view active enrollees.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "auth",
      card: "card-2",
      icon: "insert_comment",
      title: "Pre-Authorizations",
      description: "manage pre-authorization.",
      notification: "new authorization",
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "provider",
      card: "card-1",
      icon: "local_hospital",
      title: "Providers",
      description: "manage the providers.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "hospital",
      card: "card-2",
      icon: "label",
      title: "Hospitals",
      description: "view primary care hospital.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "claim",
      card: "card-1",
      icon: "description",
      title: "Claims",
      description: "manage claims.",
      notification: "new submission",
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "private",
      card: "card-2",
      icon: "business",
      title: "HMO Clients",
      description:
        "Manage (HMO) clients, contracts, enrollees, invoices and more.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "public",
      card: "card-1",
      icon: "group_add",
      title: "Schemes",
      description:
        "Manage all State Social Health Insurance Schemes. Upload and review monthly lists.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "retail",
      card: "card-1",
      icon: "shopping_cart",
      title: "Retail Clients",
      description:
        "Manage (Retail) clients, contracts, enrollees, invoices and more.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "tpa",
      card: "card-2",
      icon: "monetization_on",
      title: "TPA (Retainer) Clients",
      description:
        "Manage (TPA) clients, contracts, enrollees, invoices and more.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "client",
      card: "card-2",
      icon: "business",
      title: "Clients",
      description: "View all clients",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "advisory",
      card: "card-2",
      icon: "work",
      title: "Advisory",
      description: "View upcoming invoices for prompt payment.",
      notification: "new pending",
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "adder",
      card: "card-2",
      icon: "add_circle",
      title: "Adders",
      description: "Quickly add dependants to active enrollees.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "compliance",
      card: "card-1",
      icon: "check_circle",
      title: "Compliance",
      description: "Review and approve claims. View invoices.",
      notification: "new claim",
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "finance",
      card: "card-1",
      icon: "account_balance",
      title: "Finance",
      description: "Pay claims. Confirm client payments.",
      notification: "new batch",
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "report",
      card: "card-2",
      icon: "show_chart",
      title: "Insights",
      description: "View latest utilization patterns by clients, providers.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "settings",
      card: "card-1",
      icon: "settings",
      title: "Settings",
      description:
        "Load Shelf Plans, Standard Tariffs, Create Users and other settings.",
      notification: null,
    });

    tabs.value.push({
      id: tabs.value.length + 1,
      to: "bypass",
      card: "card-1",
      icon: "file_upload",
      title: "Bypass",
      description:
        "Bypass the standard invoicing system - Directly add enrollees to the monthly list.",
      notification: null,
    });

    return {
      tabs,
    };
  },
};
</script>

<style>
.card-welcome {
  border-radius: 4px;
  background: #fff;
  padding: 14px 80px 5px 36px;
  margin: 20px;
  cursor: pointer;
}

.card-dashboard {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 80px 18px 36px;
  margin: 20px;
  cursor: pointer;
}

.card-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-dashboard h4 {
  font-weight: 510;
  font-size: 2em;
  color: #2e4053;
}

.card-dashboard img {
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card-1 {
  background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-2 {
  background-image: url(https://ionicframework.com/img/getting-started/components-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-3 {
  background-image: url(https://ionicframework.com/img/getting-started/theming-card.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

@media (max-width: 990px) {
  .card-dashboard {
    margin: 20px;
  }
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>