import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'easyHMO',
  storage: window.sessionStorage,
  reducer: (state) => ({ user: state.user, role: state.role, masterSchemes: state.masterSchemes, schemes: state.schemes, modules: state.modules, authLevel: state.authLevel, cache: state.cache })
})

export default createStore({
  state: {
    user: null,
    role: null,
    masterSchemes: null,
    schemes: null,
    modules: null,
    authLevel: null,
    cache: [],
  },
  plugins: [vuexPersist.plugin],
  getters: {
  },
  mutations: {
    loadUser(state, payload) {
      state.user = payload;
    },
    loadRole(state, payload) {
      state.role = payload;
    },
    loadMasterSchemes(state, payload) {
      state.masterSchemes = payload;
    },
    loadSchemes(state, payload) {
      state.schemes = payload;
    },
    loadModules(state, payload) {
      state.modules = payload;
    },
    loadAuthLevel(state, payload) {
      state.authLevel = payload;
    },
    loadCache(state, payload) {
      state.cache = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
