<template>
  <div class="row">
    <div class="col s12">
      <LoadingDiv v-if="!isReady" />

      <div class="section" v-else>
        <!-- Claims details -->
        <div class="card-panel">
          <div class="row">
            <div class="col s1">#</div>
            <div class="col s2">
              <b>Batch #</b>
            </div>
            <div class="col s2">Counter</div>
            <div class="col s2">Total Amount</div>
            <div class="col s2">Status</div>
            <div class="col s2">
              Ref Date
            </div>
            <div class="col s1"><br /></div>
          </div>
        </div>

        <div class="card">
        <div class="card" v-for="(batch, index) in batches" :key="batch.id">
          <div
            class="card-panel"
            :class="{
              'blue-grey lighten-5':
                ((index + 1) % 2 === 1 || currBatch == batch) && batch.stage == 'c', 
              'yellow lighten-5': batch.stage == 'q', 
            }"
            v-if="currBatch == batch || !currBatch"
          >
            <div class="row">
              <div class="col s1">
                <p>{{ numberBadge(index) }}</p>
              </div>
              <div class="col s2">
                <h6>
                  <span v-if="batch.stage == 'q'">Request<br /></span>
                  <span v-if="batch.stage == 'c'">Payment<br /></span>
                  Batch #{{ batch.id }}
                  <span
                    class="chip blue lighten-5"
                    v-if="batch.batch_type == 's' && batch.stage == 'q'"
                    >Supplementary</span
                  >
                </h6>
              </div>
              <div class="col s2">
                <p>{{ batch.clusters.length }} Providers</p>
              </div>
              <div class="col s2">
                <p v-if="batch.stage == 'q'">
                  <b>₦{{ batchTotalApproved(batch) }}</b>
                </p>
                <p v-if="batch.stage == 'c'">
                  ₦{{ batchTotalApproved(batch) }}
                </p>
              </div>
              <div class="col s2">
                <p>
                  <span class="chip" v-if="batch.stage == 'q'">unpaid</span>
                  <span class="chip green lighten-4" v-if="batch.stage == 'c'"
                    >paid</span
                  >
                </p>
              </div>
              <div class="col s2">
                <p>
                  <span class="chip" v-if="batch.stage == 'q'">pending</span>
                </p>
                <p v-if="batch.stage == 'c'">
                  {{
                    new Date(batch.paid_date).toLocaleString(undefined, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }}
                </p>
              </div>
              <div class="col s1">
                <span v-if="!currBatch">
                  <a
                    class="btn-floating btn-medium waves-effect waves-light right"
                    :class="{
                      'light-blue lighten-2': batch.stage == 'c',
                      'red pulse': batch.stage == 'q',
                    }"
                    ><i
                      class="material-icons"
                      @click="handleView(batch)"
                      title="details"
                      >chevron_right</i
                    ></a
                  >
                </span>
                <span class="right" v-else>
                  <a
                    class="btn-floating btn-medium waves-effect waves-light light-blue right"
                    ><i
                      class="material-icons"
                      @click="handleClear()"
                      title="go back"
                      >keyboard_return</i
                    ></a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>

        <!-- Batch details -->
        <BatchDiv
          :batchId="currBatch.id"
          @reset="handleReset"
          v-if="currBatch"
        />

        <!-- Pages -->
        <template v-else>
          <div class="section center" v-if="!batches.length">
            <h6>No Claims <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </template>
      </div>
    </div>
  </div>
</template>
              
  <script>
import { computed, onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import BatchDiv from "@/components/finance/FinanceSingleBatchComponent.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "FinanceClaimsComponent",
  components: { LoadingDiv, PaginationDiv, BatchDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const reLoad = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currBatch = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // total calculations (clustered approved)
    const batchTotalApproved = (batch) => {
      let total = 0;
      batch.clusters.forEach((b) => {
        b.encounters.forEach((e) => {
          e.claims.forEach((c) => {
            if (c.approved_price && c.status == "a") {
              total = Number(total) + Number(c.approved_price);
            }
          });
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // convert to Month & Year
    const toMonthYear = (val) => {
      let parts = val.split("_");
      let month = parseInt(parts[0]);
      let year = parseInt(parts[1]);
      let date = new Date(year, month - 1); // months are zero-indexed in JavaScript
      let monthName = date.toLocaleString("default", { month: "short" });
      return monthName + " " + year;
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // get started
      getPendingBatches();
    });

    // get fresh load
    const getPendingBatches = () => {
      // set current Url
      currUrl.value = "cluster/finance/vetted";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // update batch
    const handleReset = (val) => {
      // mark for reload
      reLoad.value = true;

      // reset
      if (val != "reload") {
        response.value.data.forEach((x, y) => {
          if (x.id == val.id) {
            // make changes
            return (response.value.data[y].clusters = val.clusters);
          }
        });
      } else if (val == "reload") {
        // handle clear
        handleClear();
      }
    };

    // enrollee action
    const handleClear = () => {
      // reLoad if necessary
      if (reLoad.value) {
        isReady.value = false;
        getPendingBatches();
      }
      // clear
      currBatch.value = null;
      reLoad.value = false;
    };

    // enrollee action
    const handleView = (val) => {
      currBatch.value = val;
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // output results
    const batches = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data; // get data portion
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      numberBadge,
      batchTotalApproved,
      toMonthYear,
      handleReset,
      handlePage,
      handleClear,
      handleView,
      currServerDate,
      currBatch,
      isReady,
      isLoading,
      error,
      batches,
      allPages,
    };
  },
};
</script>
              
  <style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>
      